import Box from "@mui/material/Box";
import OmniSearchFilter from "../../components/OmniSearchFilter";
import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import PeopleListWidget from "../../components/PeopleListWidget";

import CounterBox from "../../components/CounterBox";

import { usePersonsOmniSearch } from "../../query/search";
import { useEffect, useState } from "react";
import { Pagination, SearchFilters } from "../../types/Search";

import ContentWidget from "../../components/ContentWidget";
import useInfiniteScroll from "react-infinite-scroll-hook";
import AddOrCreateListModal from "../../components/AddOrCreateListModal";

const Search = () => {
  const [filter, setFilter] = useState<SearchFilters>({});
  const [selectedPersonId, setSelectedPersonId] = useState<string>("");
  const [listsModalOpen, setListsModalOpen] = useState<boolean>(false);
  const [paginationPersons] = useState<Pagination>({ offset: 0, limit: 20 });
  const [addToListPersonIds, setAddToListPersonIds] = useState<string[]>([]);
  const [isPersonsSearch, setIsPersonsSearch] = useState(true);
  const {
    data: persons,
    isFetching: personsFetching,
    error,
    loadMore,
    hasNextPage,
  } = usePersonsOmniSearch(paginationPersons.limit, "id", filter);

  useEffect(() => {
    if (persons && persons?.length) {
      if (!selectedPersonId) {
        setSelectedPersonId(persons[0].id);
      }
    }
  }, [persons, selectedPersonId]);
  useEffect(() => {
    setSelectedPersonId("");
  }, [filter]);

  const [sentryRef] = useInfiniteScroll({
    loading: personsFetching,
    hasNextPage: hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  const handleSelectPersonForList = (
    e: React.ChangeEvent<HTMLInputElement>,
    personId: string,
  ) => {
    if (e.target.checked) {
      setAddToListPersonIds([...addToListPersonIds, personId]);
    } else {
      setAddToListPersonIds(addToListPersonIds.filter((id) => id !== personId));
    }
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent={"center"}
        bgcolor={(theme) => theme.palette.primary.main}
      >
        <Typography variant="subtitle2" color="white">
          &#9432; Please note, the Search functionality is currently in demo
          mode
        </Typography>
      </Box>
      <OmniSearchFilter
        setFilter={setFilter}
        isPersonsSearch={isPersonsSearch}
        setIsPersonsSearch={setIsPersonsSearch}
      />
      {personsFetching && !persons ? (
        <Box display="flex" justifyContent={"center"}>
          <Box position="absolute" sx={{ top: "60%" }}>
            <Stack direction={"column"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          </Box>
        </Box>
      ) : null}

      {persons && persons?.length ? (
        <>
          <Box
            display="flex"
            py={3}
            px={5}
            justifyContent="space-between"
            alignItems="center"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Box>
              <Typography variant="h2">Your search results</Typography>
            </Box>
            <Box gap={2} display="flex">
              <Button
                variant="contained"
                disabled={addToListPersonIds.length === 0}
                startIcon={<DownloadIcon />}
                size="small"
              >
                Export all
              </Button>
              <Button
                variant="contained"
                disabled={addToListPersonIds.length === 0}
                startIcon={<DownloadIcon />}
                size="small"
                endIcon={
                  <CounterBox
                    count={addToListPersonIds.length}
                    disabled={addToListPersonIds.length === 0}
                  />
                }
              >
                Export selected to CSV
              </Button>
              <Button
                variant="contained"
                size="small"
                disabled={addToListPersonIds.length === 0}
                endIcon={
                  <CounterBox
                    count={addToListPersonIds.length}
                    disabled={addToListPersonIds.length === 0}
                  />
                }
                onClick={() => setListsModalOpen(true)}
                startIcon={
                  <Typography
                    variant="h2"
                    sx={{
                      color: addToListPersonIds.length === 0 ? "grey" : "white",
                    }}
                  >
                    +
                  </Typography>
                }
              >
                Add selected to a list
              </Button>
            </Box>
          </Box>

          <Grid container spacing={2} p={3}>
            {isPersonsSearch ? (
              <Grid item xs={4}>
                <PeopleListWidget
                  persons={persons}
                  setSelectedPersonId={setSelectedPersonId}
                  selectedPersonId={selectedPersonId}
                  sentryRef={sentryRef}
                  hasNextPage={hasNextPage}
                  loading={personsFetching}
                  handleSelectPersonForList={handleSelectPersonForList}
                />
              </Grid>
            ) : null}
            <Grid item xs={isPersonsSearch ? 8 : 12}>
              <ContentWidget
                filter={filter}
                selectedPersonId={
                  isPersonsSearch ? selectedPersonId : undefined
                }
              />
            </Grid>
          </Grid>

          <AddOrCreateListModal
            people={addToListPersonIds}
            open={listsModalOpen}
            toggle={() => setListsModalOpen(!listsModalOpen)}
          />
        </>
      ) : null}
      {persons && !persons.length ? (
        <Box display="flex" justifyContent={"center"} mt={4}>
          <Box textAlign={"center"} sx={{ top: "50%" }}>
            <Stack direction={"column"} spacing={2} alignItems={"center"}>
              <Typography variant="h2">Nothing found</Typography>
              <Typography variant="h3">
                Try searching with different filters
              </Typography>
            </Stack>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};
export default Search;
