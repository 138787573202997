import Box from "@mui/material/Box";

import List from "@mui/material/List";

import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import { CSSObject, Theme, styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import CastOutlinedIcon from "@mui/icons-material/CastOutlined";
import MarkUnreadChatAltOutlinedIcon from "@mui/icons-material/MarkUnreadChatAltOutlined";
import { ReactComponent as DashboardIcon } from "../assets/icons/dashboard.svg";
import { ReactComponent as PMLogo } from "../assets/images/pm_logo.svg";
import { ReactComponent as PMLogoMini } from "../assets/images/pm_logo_mini.svg";

import XIcon from "@mui/icons-material/X";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import SupportOutlinedIcon from "@mui/icons-material/SupportOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import { useContext, useState } from "react";
import {
  EXTERNAL_PATHS,
  isFeatureEnabled,
  logOut,
  redirectToExternal,
} from "./utils";
import { Features, User } from "../types/User";
import { AuthContext } from "../context/AuthContext";

const drawerWidth = "256px";
const drawerWidthCollapsed = "68px";

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),

  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  overflowX: "hidden",
  width: drawerWidthCollapsed,
  [theme.breakpoints.up("sm")]: {
    width: drawerWidthCollapsed,
  },
});

type SidebarItemProps = {
  name: string;
  link: string | EXTERNAL_PATHS;
  icon: React.ReactNode;
  disabled?: boolean;
};

type SidebarRedirectItemProps = {
  dashboard: SidebarItemProps[];
  manage: SidebarItemProps[];
  settings: SidebarItemProps[];
};

const getlistItemsDict = (user: User | null): SidebarRedirectItemProps => {
  return {
    dashboard: [
      {
        name: "X (Twitter)",
        link: EXTERNAL_PATHS.TWITTER_DASHBOARD,
        icon: <XIcon />,
      },
      {
        name: "Publications",
        link: EXTERNAL_PATHS.PUBLICATIONS,
        icon: <NewspaperOutlinedIcon />,
      },
      {
        name: "Broadcasts",
        link: EXTERNAL_PATHS.BROADCAST_DASHBOARD,
        icon: <CastOutlinedIcon />,
      },
      {
        name: "Briefings Alerts",
        link: EXTERNAL_PATHS.BRIEFINGS_DASHBOARD,
        icon: <MarkUnreadChatAltOutlinedIcon />,
      },
    ],
    manage: [
      { name: "Overview", link: "/", icon: <DashboardIcon /> },
      { name: "People", link: "/people", icon: <ContactMailOutlinedIcon /> },
      {
        name: "Contacts",
        link: "/contacts",
        icon: <ContactPageOutlinedIcon />,
      },
      {
        name: "Emails",
        link: "/emails",
        icon: <EmailOutlinedIcon />,
        disabled: !isFeatureEnabled(user, Features.EMAILING),
      },
      { name: "Search", link: "/search", icon: <SearchOutlinedIcon /> },
      {
        name: "Lists",
        link: "/lists",
        icon: <FormatListBulletedOutlinedIcon />,
      },
      {
        name: "Engagements",
        link: "/people",
        icon: <RateReviewOutlinedIcon />,
        disabled: true,
      },
    ],
    settings: [
      { name: "Help", link: "/", icon: <SupportOutlinedIcon /> },
      { name: "Logout", link: "/", icon: <LogoutOutlinedIcon /> },
    ],
  };
};

const SidebarItem = (
  name: string,
  link: string,
  icon: React.ReactNode,
  open: boolean,
  disabled?: boolean,
) => {
  return (
    <ListItem key={name} disablePadding>
      {disabled ? (
        <ListItemButton disabled={disabled}>
          <ListItemIcon sx={{ color: "common.grey" }}>{icon}</ListItemIcon>
          {open ? <ListItemText primary={name} /> : null}
        </ListItemButton>
      ) : (
        <ListItemButton component={Link} to={link} disabled={disabled}>
          <ListItemIcon sx={{ color: "common.black" }}>{icon}</ListItemIcon>
          {open ? <ListItemText primary={name} /> : null}
        </ListItemButton>
      )}
    </ListItem>
  );
};

const SidebarRedirectItem = (
  name: string,
  link: EXTERNAL_PATHS,
  icon: React.ReactNode,
  open: boolean,
  disabled?: boolean,
) => {
  return (
    <ListItem key={name} disablePadding>
      {disabled ? (
        <ListItemButton disabled={disabled}>
          <ListItemIcon sx={{ color: (theme) => theme.palette.grey[900] }}>
            {icon}
          </ListItemIcon>
          {open ? <ListItemText primary={name} /> : null}
        </ListItemButton>
      ) : (
        <ListItemButton
          onClick={(e) => redirectToExternal(link, e)}
          disabled={disabled}
        >
          <ListItemIcon sx={{ color: "common.black" }}>{icon}</ListItemIcon>
          {open ? <ListItemText primary={name} /> : null}
        </ListItemButton>
      )}
    </ListItem>
  );
};

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Sidebar = () => {
  const [open, setOpen] = useState(true);
  const { user } = useContext(AuthContext);
  const listItemsDict = getlistItemsDict(user);
  const handleDrawer = () => {
    setOpen(!open);
  };
  return (
    <StyledDrawer
      open={open}
      sx={{
        "& .MuiDrawer-paper": {
          background: (theme) => theme.palette.secondary.main,
          px: open ? 2 : 1,
          py: 2,
        },
        ".MuiListItemButton-root": {
          p: 1.5,
        },
        ".MuiListItemIcon-root": {
          minWidth: "40px",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      {open ? (
        <Box
          pt={1}
          pb={3}
          pr={0}
          justifyContent="space-between"
          display="flex"
          alignItems={"center"}
        >
          <PMLogo />

          <IconButton onClick={handleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Box>
      ) : (
        <Box
          pt={1}
          pb={1}
          pr={0}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <PMLogoMini />

          <IconButton onClick={handleDrawer}>
            <ChevronRightIcon />
          </IconButton>
        </Box>
      )}
      <Divider />

      <Box>
        <List sx={{ my: 2 }}>
          <Typography
            variant="subtitle2"
            display={open ? "block" : "none"}
            sx={{ color: (theme) => theme.palette.grey[500], pb: 1 }}
          >
            Dashboard
          </Typography>
          {listItemsDict.dashboard.map((item) =>
            SidebarRedirectItem(
              item.name,
              item.link as EXTERNAL_PATHS,
              item.icon,
              open,
            ),
          )}
        </List>
        <Divider />
        <List sx={{ my: 2 }}>
          <Typography
            variant="subtitle2"
            display={open ? "block" : "none"}
            sx={{ color: (theme) => theme.palette.grey[500], pb: 1 }}
          >
            Manage
          </Typography>
          {listItemsDict.manage.map((item) =>
            SidebarItem(item.name, item.link, item.icon, open, item?.disabled),
          )}
        </List>
        <Divider />
        <List sx={{ my: 2 }}>
          <Typography
            variant="subtitle2"
            display={open ? "block" : "none"}
            sx={{ color: (theme) => theme.palette.grey[500], pb: 1 }}
          >
            Settings
          </Typography>
          <ListItemButton onClick={(e) => logOut(e)}>
            <ListItemIcon sx={{ color: "common.black" }}>
              <LogoutOutlinedIcon />
            </ListItemIcon>
            {open ? <ListItemText primary="Logout" /> : null}
          </ListItemButton>
        </List>
      </Box>
    </StyledDrawer>
  );
};

export default Sidebar;
