import "./App.css";
import { Outlet } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./context/AuthContext";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@emotion/react";
import { defaultTheme } from "./theme";
import Header from "./components/Header";
import { Box } from "@mui/material";
import Sidebar from "./components/Sidebar";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import { SnackbarProvider } from "notistack";
import { NotificationService } from "./services/NotificationService";
import Footer from "./components/Footer";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ErrorBoundaryPage from "./components/ErrorBoundaryPage";

import HubspotWidgetComponent from "./components/HubspotWidgetComponent";

const queryClient = new QueryClient();

export const ErrorPageWithLayout = () => {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={defaultTheme}>
        <ErrorBoundaryPage />
      </ThemeProvider>
    </>
  );
};

function App() {
  const isProduction = process.env.NODE_ENV === "production";

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction}
      isVerboseMode={false}
    >
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <CssBaseline />
        <SnackbarProvider
          maxSnack={5}
          ref={NotificationService.Ref}
          autoHideDuration={5000}
        >
          <ThemeProvider theme={defaultTheme}>
            <AuthProvider>
              <HubspotWidgetComponent />
              <Box display="flex" justifyContent="space-between">
                <Sidebar />
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    height: "100vh",
                    overflow: "auto",
                  }}
                >
                  <Header />
                  <Box mb={10}>
                    <Outlet />
                  </Box>
                  <Footer />
                </Box>
              </Box>
            </AuthProvider>
          </ThemeProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </CacheBuster>
  );
}

export default App;
