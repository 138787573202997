import {
  Box,
  Button,
  Grid,
  Stack,
  SvgIcon,
  Typography,
  styled,
} from "@mui/material";

import ListsTableContainer from "./Lists/Lists";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { ReactComponent as hocIcon } from "../assets/images/logos/Crowned_Portcullis_redesign_2018.svg";
import { ReactComponent as holIcon } from "../assets/images/logos/house_of_lords.svg";
import { ReactComponent as scotIcon } from "../assets/images/logos/scottish_parliament_logo.svg";
import { ReactComponent as seneddIcon } from "../assets/images/logos/SeneddLogo.svg";
import { ReactComponent as NIAIcon } from "../assets/images/logos/NI_assembly.svg";
import { Link } from "react-router-dom";

const StyledDashboardButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.common.white,
  borderColor: theme.palette.divider,
  display: "flex",
}));

export const Dashboard = () => {
  return (
    <Box>
      <Stack
        direction={"column"}
        py={4}
        borderBottom={1}
        borderColor={"divider"}
        px={8}
        minHeight={120}
        justifyContent={"space-between"}
      >
        {/* TODO: Add relevant links */}
        <Stack direction="column" gap={2} py={2}>
          <Typography variant="h1">PoliMonitor People</Typography>
          <Typography variant="subtitle1">
            Look up biographies and contact details, and manage your
            relationship with people.
          </Typography>
        </Stack>
        <Grid container spacing={2} py={2}>
          <Grid item xs={4}>
            <Link
              to="/people"
              state={{ initialFilter: "HoC" }}
              style={{ textDecoration: "auto" }}
            >
              <StyledDashboardButton
                variant="outlined"
                size="small"
                fullWidth
                startIcon={<SvgIcon inheritViewBox component={hocIcon} />}
                endIcon={<ArrowOutwardIcon />}
              >
                House of Commons
              </StyledDashboardButton>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link
              to="/people"
              state={{ initialFilter: "HoL" }}
              style={{ textDecoration: "auto" }}
            >
              <StyledDashboardButton
                variant="outlined"
                size="small"
                fullWidth
                startIcon={<SvgIcon inheritViewBox component={holIcon} />}
                endIcon={<ArrowOutwardIcon />}
              >
                House of Lords
              </StyledDashboardButton>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link
              to="/people"
              state={{ initialFilter: "SCOTPARL" }}
              style={{ textDecoration: "auto" }}
            >
              <StyledDashboardButton
                variant="outlined"
                fullWidth
                size="small"
                startIcon={<SvgIcon inheritViewBox component={scotIcon} />}
                endIcon={<ArrowOutwardIcon />}
              >
                Scottish Parliament
              </StyledDashboardButton>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link
              to="/people"
              state={{ initialFilter: "Senedd" }}
              style={{ textDecoration: "auto" }}
            >
              <StyledDashboardButton
                variant="outlined"
                fullWidth
                size="small"
                startIcon={<SvgIcon inheritViewBox component={seneddIcon} />}
                endIcon={<ArrowOutwardIcon />}
              >
                Senedd
              </StyledDashboardButton>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link
              to="/people"
              state={{ initialFilter: "NIASSEMBLY" }}
              style={{ textDecoration: "auto" }}
            >
              <StyledDashboardButton
                variant="outlined"
                fullWidth
                size="small"
                startIcon={<SvgIcon inheritViewBox component={NIAIcon} />}
                endIcon={<ArrowOutwardIcon />}
              >
                Northern Ireland Assembly
              </StyledDashboardButton>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to="/people" style={{ textDecoration: "auto" }}>
              <StyledDashboardButton
                variant="outlined"
                fullWidth
                size="small"
                endIcon={<ArrowOutwardIcon />}
              >
                View all
              </StyledDashboardButton>
            </Link>
          </Grid>
        </Grid>
      </Stack>

      <ListsTableContainer />
    </Box>
  );
};
