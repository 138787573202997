import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { memo, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { replaceDotsWithUnderscores } from "../containers/Persons/utils";
import CounterBox from "./CounterBox";

type CollapsableRowProps = {
  header: string;
  id: string;
  children?: any;
  collapsable?: boolean;
  opened?: boolean;
  control?: Control<Record<string, boolean>>;
  hasCheckbox?: boolean;
  count?: number;
  parentId?: string;
};

const CollapsableRow = ({
  children,
  header,
  collapsable = true,
  hasCheckbox = false,
  id,
  opened,
  control,
  count,
  parentId,
}: CollapsableRowProps) => {
  const [isOpen, setOpen] = useState(opened);
  const handleClick = () => {
    setOpen(!isOpen);
  };

  const idWithUnderscores = replaceDotsWithUnderscores(id);

  return (
    <Box id={`${idWithUnderscores}_${isOpen}`}>
      <ListItemButton onClick={handleClick} sx={{ pr: 1 }}>
        {collapsable ? (
          <ListItemIcon sx={{ minWidth: 0 }}>
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
        ) : null}

        {!collapsable || hasCheckbox ? (
          <Controller
            control={control}
            name={
              parentId ? `${parentId}.${idWithUnderscores}` : idWithUnderscores
            }
            render={({ field }) => (
              <Checkbox
                {...field}
                size="small"
                checked={field.value}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              />
            )}
          />
        ) : null}
        <ListItemText
          primary={header}
          primaryTypographyProps={{ variant: "subtitle2", color: "black" }}
        />
        {collapsable && count ? <CounterBox count={count} /> : null}
      </ListItemButton>
      {collapsable ? (
        <Collapse in={isOpen} unmountOnExit>
          {children}
        </Collapse>
      ) : null}
    </Box>
  );
};

export default memo(CollapsableRow);
