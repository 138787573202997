import { Link, useLoaderData, useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";

import { usePersonsAll } from "../../query/person";

import { useGetList, useUpdateListMutation } from "../../query/list";
import PersonsTable from "./PersonsTableBasic";
import { NoteBulkCreateModalContainer } from "../Engagements/NotesModals";
import { handlePersonsExport } from "../../services/utils";
import { PATHS } from "../../router";
import AddOrCreateListModal from "../../components/AddOrCreateListModal";
import { getSelectedEmails } from "../Persons/utils";

const ListTableContainer = () => {
  const { id } = useLoaderData() as { id: string };
  const { data: list } = useGetList(id);
  const { data: persons, isLoading } = usePersonsAll();
  const navigate = useNavigate();

  const filteredPersons = useMemo(() => {
    if (persons && list && list.person_ids) {
      return persons.results.filter((person) =>
        list.person_ids?.includes(person.id),
      );
    }
    return;
  }, [persons, list]);

  const [selected, setSelected] = useState<string[]>([]);
  const [action, setAction] = useState<number>(2);
  const { mutate: removePersons } = useUpdateListMutation();
  const [notesOpen, setNotesOpen] = useState<boolean>(false);
  const [listsModalOpen, setListsModalOpen] = useState<boolean>(false);

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    switch (action) {
      case 1:
        removePersons(
          {
            list_id: id,
            data: {
              person_ids: list?.person_ids
                ? list.person_ids.filter(
                    (person_id) => !selected.includes(person_id),
                  )
                : [],
            },
          },
          {
            onSuccess: () => {
              setSelected([]);
            },
          },
        );
        break;
      case 2:
        handlePersonsExport(e, selected);
        break;
      case 3:
        setNotesOpen(true);
        break;
      case 4:
        navigate("/emails/create", {
          state: {
            personEmails: getSelectedEmails(selected, filteredPersons),
          },
        });
        break;
      case 5:
        navigate("/emails/create", {
          state: {
            personEmails: list?.person_ids
              ? getSelectedEmails(list.person_ids, filteredPersons)
              : [],
          },
        });
        break;
      case 6:
        setListsModalOpen(true);
        break;
    }
  };

  return (
    <Box>
      <Stack
        direction={"row"}
        py={4}
        borderBottom={1}
        borderColor={"divider"}
        px={8}
        minHeight={120}
        justifyContent={"space-between"}
      >
        <Stack direction="row" alignItems={"center"} gap={2}>
          <Box
            width={23}
            height={23}
            sx={{
              backgroundColor: list?.color,
              borderRadius: "4px",
            }}
          />
          <Stack direction="column">
            <Typography variant="h1">{list?.list_name}</Typography>
            {list?.category ? (
              <Typography variant="subtitle1">
                Category: {list?.category}
              </Typography>
            ) : null}
          </Stack>
        </Stack>

        <Stack direction="row" gap={3} alignItems={"center"}>
          <Typography variant="h3">Actions:</Typography>
          <Select
            defaultValue={2}
            onChange={(e) => setAction(e.target.value as number)}
            disabled={selected.length === 0}
            sx={{
              minWidth: 280,
            }}
            size="small"
          >
            <MenuItem value={1}>Remove {selected.length} selected</MenuItem>
            <MenuItem value={2}>
              Export data for {selected.length} selected
            </MenuItem>
            <MenuItem value={3}>
              Add a note for {selected.length} selected
            </MenuItem>
            <MenuItem value={4}>Email to {selected.length} selected</MenuItem>
            <MenuItem value={5}>Email everyone in this list</MenuItem>
            <MenuItem value={6}>Add {selected.length} to a List</MenuItem>
          </Select>
          <Button
            variant="contained"
            disabled={selected.length === 0}
            onClick={handleConfirm}
          >
            Confirm selection
          </Button>
        </Stack>
      </Stack>

      {isLoading ? (
        <Box display="flex" justifyContent={"center"}>
          <Box position="absolute" sx={{ top: "50%" }}>
            <Stack direction={"column"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          </Box>
        </Box>
      ) : null}
      {filteredPersons && filteredPersons.length ? (
        <PersonsTable
          persons={filteredPersons}
          setSelected={setSelected}
          selected={selected}
        />
      ) : null}

      <NoteBulkCreateModalContainer
        personIds={selected}
        open={notesOpen}
        setOpen={setNotesOpen}
        setSelected={setSelected}
      />
      <AddOrCreateListModal
        people={selected}
        setSelected={setSelected}
        open={listsModalOpen}
        toggle={() => setListsModalOpen(!listsModalOpen)}
      />

      {filteredPersons && filteredPersons.length === 0 ? (
        <Box display="flex" justifyContent={"center"}>
          <Box textAlign={"center"} position="absolute" sx={{ top: "50%" }}>
            <Stack direction={"column"} spacing={2} alignItems={"center"}>
              <Typography variant="h2">This list is empty</Typography>
              <Typography variant="h3">
                Your list is empty, you can use the search function to find the
                perfect people to add to your list.
              </Typography>
              <Button
                variant="contained"
                component={Link}
                to={PATHS.PEOPLE}
                color="primary"
                sx={{ maxWidth: 180 }}
              >
                Find people
              </Button>
            </Stack>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default ListTableContainer;
