import {
  Box,
  Button,
  Divider,
  Modal,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";

import {
  Appg,
  Committee,
  PersonDetail,
  PersonStaff,
} from "../../../types/Person";
import { useCommittees, usePersonBio } from "../../../query/person";
import { useAppgs } from "../../../query/person";
import { useGetPersonStaff } from "../../../query/person";
import { usePDF } from "react-to-pdf";

import { useMemo } from "react";
import { BioItem, MemberBio } from "../../../types/ParlyApi";

type PersonDownloadProps = {
  person: PersonDetail;
  open: boolean;
  setOpen: (open: boolean) => void;
  personStaff: PersonStaff[] | undefined;
  filteredAppgs: { id: string; name: string; role: string }[];
  filteredCommittees: { id: string; name: string; role: string }[];
  bio?: MemberBio;
};

type PersonDownloadWrapperProps = {
  person: PersonDetail;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const PersonDownloadWrapper = ({
  person,
  open,
  setOpen,
}: PersonDownloadWrapperProps) => {
  const { data: personStaff } = useGetPersonStaff(person.id);

  const { data: appgs } = useAppgs(0, 10000);
  const filteredAppgs = useMemo(() => {
    return (
      person.appgs?.map((personAppg) => {
        const appg = appgs?.results.find((a: Appg) => a.id === personAppg.id);
        return {
          id: personAppg.id,
          name: appg?.name || "",
          role: personAppg.role,
        };
      }) || []
    );
  }, [appgs, person.appgs]);
  const { data: committees } = useCommittees(0, 10000);
  const filteredCommittees = useMemo(() => {
    return (
      person.cttees?.map((personCttee) => {
        const cttee = committees?.results.find(
          (c: Committee) => c.id === personCttee.id,
        );
        return {
          id: personCttee.id,
          name: cttee?.name || "",
          role: personCttee.role,
        };
      }) || []
    );
  }, [committees, person.cttees]);
  const { data: bio } = usePersonBio(person.parly_uid?.replace("HoL_", ""));

  return (
    <PersonDownloadModal
      person={person}
      open={open}
      setOpen={setOpen}
      personStaff={personStaff}
      filteredAppgs={filteredAppgs}
      filteredCommittees={filteredCommittees}
      bio={bio?.value}
    />
  );
};

const PastMembershipCardDownloadView = ({ content }: { content: BioItem }) => {
  return (
    <Grid item xs={6}>
      <Stack spacing={0.5}>
        <Typography variant="body2">Committee Membership</Typography>

        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
          {content.name}
        </Typography>
        <Typography variant="subtitle2">{content.additionalInfo}</Typography>
        <Stack direction="row">
          <Typography variant="subtitle2">
            {new Date(content.startDate).toLocaleDateString("en-GB")}
          </Typography>
          <Typography variant="subtitle2">{"\u00A0-\u00A0"}</Typography>
          <Typography variant="subtitle2">
            {content.endDate
              ? new Date(content.endDate).toLocaleDateString("en-GB")
              : "Present"}
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  );
};

const PersonDownloadModal = ({
  person,
  open,
  setOpen,
  personStaff,
  filteredAppgs,
  filteredCommittees,
  bio,
}: PersonDownloadProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("xl"));
  const { toPDF, targetRef } = usePDF({ filename: `${person.name}.pdf` });
  const pastBioCheck =
    bio?.representations.filter((item) => item.endDate).length ||
    bio?.governmentPosts.filter((item) => item.endDate).length ||
    bio?.oppositionPosts.filter((item) => item.endDate).length ||
    bio?.committeeMemberships.filter((item) => item.endDate).length;
  return (
    <Modal
      open={open}
      onClose={() => setOpen(!open)}
      sx={{ overflow: "scroll" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: matches ? "100%" : "120%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: 2,
        }}
      >
        <Box
          p={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => toPDF()}
          >
            Download
          </Button>
          <CloseIcon
            onClick={() => {
              setOpen(!open);
            }}
          />
        </Box>
        <Divider />
        <Box
          sx={{
            px: 3,
            py: 3,
            borderRadius: 2,
            maxWidth: 2480, // A4 width
          }}
          ref={targetRef}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            py={3}
          >
            <Box>
              <Typography variant="h1">{person.name}</Typography>
              <Typography variant="h6">
                {person.current_roles_titles}
              </Typography>
            </Box>
          </Box>
          <Grid container>
            <Grid item xs={5}>
              <Stack direction="column" spacing={1}>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    Current Constituency
                  </Typography>
                  <Typography variant="body2">
                    {person?.constituency || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    Party
                  </Typography>
                  <Typography variant="body2">
                    {person?.party || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    Website
                  </Typography>
                  <Typography variant="body2">
                    {person?.website || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    Twitter
                  </Typography>
                  <Typography variant="body2">
                    {person?.twitter || "-"}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    Email address
                  </Typography>
                  <Typography variant="body2">
                    {person?.email_address || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    Parliamentary phone number
                  </Typography>
                  <Typography variant="body2">
                    {person?.parliamentary_phone_number || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    Constituency phone number
                  </Typography>
                  <Typography variant="body2">
                    {person?.office_phone_number || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    Constituency address
                  </Typography>
                  <Typography variant="body2">
                    {person?.constituency_address || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    Westminster Address
                  </Typography>
                  <Typography variant="body2">
                    {person?.ministerial_email_address || "-"}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    All-Party Parliamentary Groups
                  </Typography>
                  {filteredAppgs && filteredAppgs.length ? (
                    filteredAppgs.map((appg) => (
                      <Stack
                        direction="column"
                        spacing={0.5}
                        padding={2}
                        pl={0}
                      >
                        <Typography variant="h3">{appg.name}</Typography>
                        <Typography variant="subtitle2">{appg.role}</Typography>
                      </Stack>
                    ))
                  ) : (
                    <Typography variant="body2">{"-"}</Typography>
                  )}
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    Current Committees
                  </Typography>

                  {filteredCommittees && filteredCommittees.length ? (
                    filteredCommittees.map((cttee) => (
                      <Stack
                        direction="column"
                        spacing={0.5}
                        padding={2}
                        pl={0}
                      >
                        <Typography variant="h3">{cttee.name}</Typography>
                        <Typography variant="subtitle2">
                          {cttee.role}
                        </Typography>
                      </Stack>
                    ))
                  ) : (
                    <Typography variant="body2">{"-"}</Typography>
                  )}
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    Staff
                  </Typography>

                  {personStaff && personStaff.length ? (
                    personStaff.map((staff) => (
                      <Stack
                        direction="column"
                        spacing={0.5}
                        padding={2}
                        pl={0}
                      >
                        <Typography variant="h3">
                          {staff.staff_fullname}
                        </Typography>
                        <Typography variant="h4">
                          {staff.staff_email_verified || staff.staff_email}
                        </Typography>
                      </Stack>
                    ))
                  ) : (
                    <Typography variant="body2">{"-"}</Typography>
                  )}
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="column" spacing={1}>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    Summary
                  </Typography>
                  <Typography variant="body2">
                    {person?.bio_summary || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    Early life
                  </Typography>
                  <Typography variant="body2">
                    {person?.biog_2_early_life || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    Career
                  </Typography>
                  <Typography variant="body2">
                    {person?.biog_3_professional_career || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    Political career
                  </Typography>
                  <Typography variant="body2">
                    {person?.biog_4_political_career || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    Personal life
                  </Typography>
                  <Typography variant="body2">
                    {person?.biog_5_personal_life || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    Controversies
                  </Typography>
                  <Typography variant="body2">
                    {person?.biog_6_controversies || "-"}
                  </Typography>
                </Box>

                {pastBioCheck ? (
                  <Box>
                    <Divider sx={{ mt: 3, py: 2 }} />
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: "bold",
                            color: "primary.main",
                            mt: 3,
                          }}
                        >
                          Previous Parliamentary Career
                        </Typography>
                      </Grid>
                      {bio?.representations
                        .filter((item) => item.endDate)
                        .map((content: BioItem) => (
                          <PastMembershipCardDownloadView content={content} />
                        ))}
                      {bio?.governmentPosts
                        .filter((item) => item.endDate)
                        .map((content: BioItem) => (
                          <PastMembershipCardDownloadView content={content} />
                        ))}
                      {bio?.oppositionPosts
                        .filter((item) => item.endDate)
                        .map((content: BioItem) => (
                          <PastMembershipCardDownloadView content={content} />
                        ))}
                      {bio?.committeeMemberships
                        .filter((item) => item.endDate)
                        .map((content: BioItem) => (
                          <PastMembershipCardDownloadView content={content} />
                        ))}
                    </Grid>
                  </Box>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default PersonDownloadWrapper;
