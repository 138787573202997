export const POLIMONITOR_MAIN_APP_URL =
  process.env.NODE_ENV === "production"
    ? "https://app.polimonitor.com"
    : "http://localhost:5000";

export const API_PATHS = {
  PERSONS: "/persons",
  NOTES: "/notes",
  PERSONS_SEARCH: "/v1/persons/search",
  LISTS: "/lists",
  REGIONS: "/regions",
  PARTIES: "/parties",
  COMMITTEES: "/committees",
  APPGS: "/appgs",
  CONTENT_TYPES: "/v1/search/content_types",
  SEARCH_PERSONS: "/v1/search/persons/",
  SEARCH_CONTENT: "/v1/search/content",
  PERSONS_ALL: "/persons/all",
  PERSONS_BULK_UPLOAD: "/persons/csv-bulk-upload",
  PERSONS_ELECTED: "/persons/elected",
  LISTS_ALL: "/lists/all",
  ATTACHMENTS: "/attachments",
  USERS: "/auth/users",
  PARLY_PROXY: "/parly",
  CONTACTS_ALL: "/contacts",
  EMAILS: "/emails",
  SENT_EMAILS: "/sent-emails",
};
