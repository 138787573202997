import { API_PATHS } from "../constants";
import {
  CrmSentEmail,
  EmailInputType,
  EmailStatusType,
  EmailType,
  sentEmailStatusEnumKeys,
} from "../types/Email";
import { PaginatedResponse } from "../types/utils";
import { HttpMethod, secureRequest } from "./ApiService";

export const getEmails = async (
  offset: number = 0,
  limit: number = 25,
  q?: string,
  sent_by?: string,
  status?: string,
): Promise<PaginatedResponse<EmailType>> => {
  const searchParams = new URLSearchParams();
  searchParams.set("limit", String(limit));
  searchParams.set("offset", String(offset));
  if (q) {
    searchParams.set("q", q);
  }
  if (sent_by) {
    searchParams.set("sent_by", sent_by);
  }
  if (status) {
    searchParams.set("status", status);
  }

  return await secureRequest(
    HttpMethod.Get,
    API_PATHS.EMAILS + "/?" + searchParams,
    {},
  );
};

export const getEmail = async (emailId: string): Promise<EmailType> => {
  return await secureRequest(
    HttpMethod.Get,
    API_PATHS.EMAILS + "/" + emailId,
    {},
  );
};

export const createEmail = async (data: EmailInputType): Promise<EmailType> => {
  return await secureRequest(HttpMethod.Post, API_PATHS.EMAILS, { data });
};

type UpdateEmailInput = {
  emailId: string;
  data: EmailInputType;
};

export const updateEmail = async ({
  emailId,
  data,
}: UpdateEmailInput): Promise<EmailType> => {
  return await secureRequest(
    HttpMethod.Patch,
    API_PATHS.EMAILS + "/" + emailId,
    { data },
  );
};

export const sendEmail = async ({
  emailId,
  test,
  test_email,
}: {
  emailId: string;
  test?: boolean;
  test_email?: string;
}): Promise<EmailType> => {
  const data = test ? { data: { test, test_email } } : {};
  return await secureRequest(
    HttpMethod.Post,
    API_PATHS.EMAILS + "/" + emailId + "/send",
    data,
  );
};

export const getSentEmailsForPerson = async (
  offset: number = 0,
  limit: number = 25,
  personId: string,
  q?: string,
): Promise<PaginatedResponse<CrmSentEmail>> => {
  const searchParams = new URLSearchParams();
  searchParams.set("limit", String(limit));
  searchParams.set("offset", String(offset));
  if (q) {
    searchParams.set("q", q);
  }
  const url = `${API_PATHS.PERSONS}/${personId}/emails/?${searchParams}`;

  return await secureRequest(HttpMethod.Get, url, {});
};

export const getSentEmailStatus = async (
  personId: string,
  emailId: string,
): Promise<typeof sentEmailStatusEnumKeys | undefined> => {
  const url = `${API_PATHS.PERSONS}/${personId}/emails/${emailId}/status/last`;
  return await secureRequest(HttpMethod.Get, url, {});
};

export const getSentEmail = async (emailId: string): Promise<CrmSentEmail> => {
  const url = `${API_PATHS.SENT_EMAILS}/${emailId}`;

  return await secureRequest(HttpMethod.Get, url, {});
};

export const getSentEmailDeliveryStats = async (
  emailId: string,
): Promise<EmailStatusType> => {
  const url = API_PATHS.EMAILS + "/" + emailId + "/stats";
  return await secureRequest(HttpMethod.Get, url, {});
};

export const getEmailPreviewForPerson = async (
  emailId: string,
  personId: string,
): Promise<CrmSentEmail> => {
  const url = API_PATHS.EMAILS + "/" + emailId + "/preview/" + personId;
  return await secureRequest(HttpMethod.Get, url, {});
};
