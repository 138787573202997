import Box from "@mui/material/Box";

import {
  Avatar,
  AvatarGroup,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useListsForPerson } from "../../query/list";
import { getInitialsFromName } from "./utils";

type ListsBadgesWidgetProps = {
  personId: string;
};

const ListsBadgesWidget = ({ personId }: ListsBadgesWidgetProps) => {
  const { data: lists, isLoading } = useListsForPerson(personId);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Box width={"fit-content"}>
      {isLoading ? <CircularProgress /> : null}
      {lists ? (
        <AvatarGroup
          max={5}
          total={lists ? lists.results.length : 0}
          spacing={matches ? "medium" : "small"}
          sx={{
            ".MuiAvatar-root": {
              width: "40px",
              height: "40px",
            },
          }}
        >
          {lists.results?.map((list) => {
            return list.list_name ? (
              <Avatar
                key={list.id}
                alt={getInitialsFromName(list.list_name)}
                sx={{ backgroundColor: list.color }}
              >
                {getInitialsFromName(list.list_name)}
              </Avatar>
            ) : null;
          })}
        </AvatarGroup>
      ) : null}
    </Box>
  );
};
export default ListsBadgesWidget;
