import {
  Box,
  Typography,
  Divider,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Stack,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import ModalLayout from "../../components/ModalLayout";

import DateRangeSelect from "../../components/DateRangeSelect";
import HookedMultiSelect from "../../components/HookedMultiSelect";
import { useContentTypes, useParties, useRegions } from "../../query/search";
import { useListsAll } from "../../query/list";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { SearchFilters } from "../../types/Search";

type FilterBoxProps = {
  control: Control<SearchFilters>;
  watch: UseFormWatch<SearchFilters>;
  open: boolean;
  setOpen: (open: boolean) => void;
  isPersonsSearch: boolean;
  setIsPersonsSearch: (isPersonsSearch: boolean) => void;
  setValue: UseFormSetValue<SearchFilters>;
  handleSubmitForm: () => void;
};

const TermsFilterBox = ({
  control,
  watch,
}: {
  control: Control<SearchFilters>;
  watch: UseFormWatch<SearchFilters>;
}) => {
  const { data: regions, isLoading: regionsLoading } = useRegions();
  const { data: parties, isLoading: partiesLoading } = useParties();
  const { data: lists, isLoading: listsLoading } = useListsAll();
  return (
    <>
      {regionsLoading || partiesLoading || listsLoading ? (
        <Box display="flex" justifyContent={"center"}>
          <Stack direction={"column"} alignItems={"center"}>
            <CircularProgress />
          </Stack>
        </Box>
      ) : (
        <Stack gap={2}>
          <Divider />
          <Stack gap={1.5}>
            <Box>
              <Typography variant="subtitle1" color="textPrimary">
                Party
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                Select one or multiple political parties.
              </Typography>
            </Box>
            <HookedMultiSelect
              fullWidth
              fieldName="party"
              control={control}
              labelName="Party"
              data={parties}
              selection={watch("party")}
              itemLabelName="name"
            />
          </Stack>
          <Divider />
          <Stack gap={1.5}>
            <Box>
              <Typography variant="subtitle1" color="textPrimary">
                Region
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                Select one or multiple regions.
              </Typography>
            </Box>

            <HookedMultiSelect
              fieldName="region"
              control={control}
              labelName="Region"
              valueField="name"
              data={regions}
              selection={watch("region")}
              itemLabelName="name"
            />
          </Stack>
          <Divider />
          <Stack gap={1.5}>
            <Box>
              <Typography variant="subtitle1" color="textPrimary">
                Lists
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                Select one or multiple lists.
              </Typography>
            </Box>

            <HookedMultiSelect
              fieldName="lists"
              control={control}
              labelName="Lists"
              data={lists?.results}
              selection={watch("lists")}
              itemLabelName="list_name"
            />
          </Stack>
        </Stack>
      )}
    </>
  );
};

const FilterBox = ({
  open,
  setOpen,
  isPersonsSearch,
  setIsPersonsSearch,
  setValue,
  control,
  watch,
  handleSubmitForm,
}: FilterBoxProps): JSX.Element => {
  const { data: contentTypes } = useContentTypes();
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleSubmitForm();
    setOpen(!open);
  };
  return (
    <ModalLayout open={open} toggle={() => setOpen(!open)}>
      <Stack direction={"column"} spacing={2} pt={2} pb={2}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant={"h2"} pb={2}>
            Filters
          </Typography>
          <CloseIcon onClick={() => setOpen(!open)} />
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Stack spacing={2}>
          <Box>
            <Typography variant="body2" color="textSecondary">
              Type of search
            </Typography>
            <ToggleButtonGroup
              value={isPersonsSearch}
              exclusive
              onChange={() => setIsPersonsSearch(!isPersonsSearch)}
              sx={{ mt: 1 }}
              fullWidth
            >
              <ToggleButton value={false}>Terms/Content</ToggleButton>
              <ToggleButton value={true}>People</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "row", gap: 2 }}
            width="100%"
          >
            <Box width="50%">
              <DateRangeSelect
                setValue={setValue}
                dateFrom={watch("date_from")}
                dateTo={watch("date_to")}
              />
            </Box>
            <Box width="50%">
              <HookedMultiSelect
                fullWidth
                fieldName="content_type"
                control={control}
                labelName="Type"
                data={contentTypes}
                selection={watch("content_type")}
                itemLabelName="name"
              />
            </Box>
          </Box>

          {isPersonsSearch ? (
            <TermsFilterBox control={control} watch={watch} />
          ) : null}
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button
            size="small"
            variant="outlined"
            onClick={() => setOpen(!open)}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </Stack>
      </Stack>
    </ModalLayout>
  );
};

export default FilterBox;
