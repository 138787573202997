import { SearchFilters } from "../../types/Search";

const normalizeFilterBySearchType = (
  filter: SearchFilters,
  isPersonsSearch: boolean,
) => {
  if (!isPersonsSearch) {
    return {
      content_type: filter.content_type,
      date_from: filter.date_from,
      date_to: filter.date_to,
      q: filter.q,
      exact_match: filter.exact_match,
    };
  }
  return filter;
};

export default normalizeFilterBySearchType;
