import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  AutoLink,
  Autosave,
  BlockQuote,
  Bold,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Italic,
  Link,
  Paragraph,
  PasteFromOffice,
  RemoveFormat,
  SelectAll,
  Strikethrough,
  Underline,
  Undo,
  // image
  ImageStyle,
  ImageBlock,
  ImageInsertViaUrl,
  ImageToolbar,
  ImageResize,
  ImageInline,
  Indent,
  IndentBlock,
  List,
  ListProperties,
  Highlight,
} from "ckeditor5";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import "ckeditor5/ckeditor5.css";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";

const editorConfig = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "|",
      "selectAll",
      "|",
      "fontSize",
      "fontFamily",
      "fontColor",
      "fontBackgroundColor",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "removeFormat",
      "|",
      "link",
      "blockQuote",
      "highlight",
      "bulletedList",
      "numberedList",
      "indent",
      "|",
      "alignment",
      "|",
      "accessibilityHelp",
      "insertImageViaUrl",
    ],
    shouldNotGroupWhenFull: false,
  },
  plugins: [
    AccessibilityHelp,
    Alignment,
    AutoLink,
    Autosave,
    BlockQuote,
    Bold,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    Highlight,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    ListProperties,
    Paragraph,
    PasteFromOffice,
    RemoveFormat,
    SelectAll,
    Strikethrough,
    Underline,
    Undo,
    // image
    ImageBlock,
    ImageInline,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageToolbar,
  ],
  fontFamily: {
    supportAllValues: true,
  },
  image: {
    toolbar: [
      "imageTextAlternative",
      "|",
      "imageStyle:inline",
      "imageStyle:wrapText",
      "imageStyle:breakText",
      "|",
      "resizeImage",
    ],
  },
  styles: ["full", "alignLeft", "alignRight", "alignCenter"],
  fontSize: {
    options: [10, 12, 14, "default", 18, 20, 22],
    supportAllValues: true,
  },
  placeholder: "Type or paste your content here!",
};

type CKEditorComponentProps = {
  data: string;
  disabled: boolean;
  onReady: UseFormRegister<any> | (() => void);
  onChange: UseFormSetValue<any> | (() => void);
  bodyName?: string;
};

const CKEditorComponent = ({
  data,
  disabled,
  onReady,
  onChange,
  bodyName = "content",
}: CKEditorComponentProps) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      config={editorConfig}
      disabled={disabled}
      data={data}
      onReady={(editor) => {
        // You can store the "editor" and use when it is needed.
        onReady(bodyName);
      }}
      onChange={(_, editor) => {
        onChange(bodyName, editor.getData(), { shouldDirty: true });
      }}
    />
  );
};
export default CKEditorComponent;
