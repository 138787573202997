import {
  Avatar,
  Box,
  Container,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  MRT_ColumnDef,
  MRT_GlobalFilterTextField,
  MRT_RowSelectionState,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useMemo } from "react";
import { Person, PersonListsJoinType } from "../../types/Person";

import ListsBadgesWidget from "../../components/MuiTable/ListsBadgesWidget";

import PersonBioDetail from "../../components/MuiTable/PersonBioDetail";

import { ProfileList } from "../../types/List";
import { getOrgNameFromKey } from "./utils";
import { useListsAll } from "../../query/list";
import { useNavigate } from "react-router-dom";

type PersonsTableContainerProps = {
  persons: Person[];
  rowSelection: MRT_RowSelectionState;
  setRowSelection?: (selected: MRT_RowSelectionState) => void;
  drawerOpen?: boolean;
};

const PersonsTableContainer = ({
  persons,
  setRowSelection,
  rowSelection,
  drawerOpen,
}: PersonsTableContainerProps) => {
  const { data: lists } = useListsAll();

  const personsListsJoin = useMemo(() => {
    if (lists && persons) {
      const joinedPersons = persons.map((person) => {
        const filtered = lists.results.filter((list) =>
          list.person_ids?.includes(person.id),
        );
        return { ...person, lists: filtered };
      });
      return joinedPersons;
    }
  }, [lists, persons]);

  return (
    <>
      {personsListsJoin && personsListsJoin.length ? (
        <PersonsTable
          persons={personsListsJoin}
          lists={lists?.results || []}
          setRowSelection={setRowSelection}
          rowSelection={rowSelection}
          drawerOpen={drawerOpen}
        />
      ) : null}
    </>
  );
};

type PersonsTableProps = {
  persons: PersonListsJoinType[];
  rowSelection: MRT_RowSelectionState;
  setRowSelection?: any; //TODO: fix this
  lists: ProfileList[];
  drawerOpen?: boolean;
};

const PersonsTable = ({
  persons,
  setRowSelection,
  lists,
  rowSelection,
  drawerOpen,
}: PersonsTableProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("xl"));
  const getCellWidth = (drawerOpen: boolean | undefined, matches: boolean) => {
    if (matches && !drawerOpen) {
      return 200;
    } else if (matches && drawerOpen) {
      return 100;
    } else if (!matches && !drawerOpen) {
      return 40;
    } else if (drawerOpen) {
      return 25;
    }
  };

  const columns = useMemo<MRT_ColumnDef<PersonListsJoinType>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Profile",
        size: getCellWidth(drawerOpen, matches),
        Cell: ({ row, renderedCellValue }) => (
          <Stack direction="row" alignItems={"center"} gap={2}>
            <Avatar src={row.original.img_src} />
            {renderedCellValue}
          </Stack>
        ),
      },
      {
        accessorKey: "organization",
        header: "Organization",

        accessorFn: (row) =>
          row.organization ? getOrgNameFromKey(row.organization) : "N/A",

        filterVariant: "multi-select",
        size: getCellWidth(drawerOpen, matches),
        muiFilterTextFieldProps: {
          id: "outlined-basic",
          name: "organization",
          size: "small",
          variant: "outlined",
          SelectProps: {
            renderValue: (selected: any) => {
              return selected.length ? `${selected.length} selected` : "House";
            },
          },
        },
      },
      {
        accessorKey: "region",
        accessorFn: (row) => (row.region ? row.region : "N/A"),
        header: "Region",
        filterVariant: "multi-select",
        size: getCellWidth(drawerOpen, matches),
        muiFilterTextFieldProps: {
          id: "outlined-basic",
          name: "region",
          size: "small",
          variant: "outlined",

          SelectProps: {
            // autoWidth: true,
            renderValue: (selected: any) => {
              return selected.length ? `${selected.length} selected` : "Region";
            },
          },
        },
      },
      {
        header: "Party",
        accessorKey: "party",
        size: getCellWidth(drawerOpen, matches),
      },
      {
        accessorKey: "constituency",
        header: "Constituency",
        size: getCellWidth(drawerOpen, matches),

        muiFilterTextFieldProps: {
          id: "outlined-basic",
          name: "constituency",
          size: "small",
          variant: "outlined",
          placeholder: "Constituency",
        },
      },
      {
        //TODO: add color coding via renderColumnFilterModeMenuItems
        filterVariant: "multi-select",
        accessorKey: "lists",
        size: getCellWidth(drawerOpen, matches),
        accessorFn: (row) => {
          return row?.lists ? row?.lists.map((list) => list.list_name) : [];
        },
        filterSelectOptions: lists?.map((list) => list.list_name),
        header: "Lists",

        Cell: ({ row }) => <ListsBadgesWidget personId={row.original?.id} />,
        muiFilterTextFieldProps: {
          id: "outlined-basic",
          name: "lists",
          size: "small",
          variant: "outlined",
          SelectProps: {
            renderValue: (selected: any) => {
              return selected.length ? `${selected.length} selected` : "Lists";
            },
          },
        },
      },
    ],
    [lists, drawerOpen, matches],
  );
  const table = useMaterialReactTable({
    columns,
    data: persons,

    enableTopToolbar: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableRowSelection: true,
    enableFacetedValues: true,
    enableSorting: false,
    onRowSelectionChange: setRowSelection,
    muiSelectCheckboxProps: {
      size: "small",
    },
    muiSelectAllCheckboxProps: {
      size: "small",
    },
    muiExpandAllButtonProps: {
      size: "small",
    },
    muiExpandButtonProps: {
      size: "small",
    },
    state: { rowSelection },
    selectAllMode: "all",

    muiTablePaperProps: {
      elevation: 0,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 25, 50],
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        event.preventDefault();
        navigate(`/people/${row.original.id}`);
      },
      sx: {
        cursor: "pointer",
      },
    }),
    getRowId: (row) => row.id,

    initialState: {
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-expand"],
        // right: ["mrt-row-actions"],
      },

      showGlobalFilter: true,
    },

    enableExpandAll: false,
    muiDetailPanelProps: {
      sx: {
        width: "auto",
      },
    },

    renderDetailPanel: ({ row }) =>
      row.original.id ? <PersonBioDetail personId={row.original.id} /> : null,
  });

  return (
    <Container maxWidth="xl">
      {/* 
    - control how the filters container positioned and looks like
    - control how the select components look like
    - control how the actions look like
    - control what happens when a checkbox is selected
    */}

      <Box display={"flex"} alignItems={"center"} py={3}>
        <MRT_GlobalFilterTextField
          table={table}
          sx={{ minWidth: matches ? 400 : 200 }}
        />
      </Box>

      <MaterialReactTable table={table} />
    </Container>
  );
};
export default PersonsTableContainer;
