import { Typography, useTheme } from "@mui/material";
import { truncateText } from "../services/utils";

const FormattedText = ({
  text,
  maxLength,
}: {
  text: string;
  maxLength?: number;
}) => {
  const theme = useTheme();

  const formatText = (text: string) => {
    const parts = text.split(/(<em>.*?<\/em>)/);
    return parts.map((part, index) => {
      if (part.startsWith("<em>") && part.endsWith("</em>")) {
        const innerText = part.replace(/<\/?em>/g, "");
        return (
          <Typography
            key={index}
            component="span"
            sx={{ color: theme.palette.primary.main, fontWeight: "bold" }}
          >
            {innerText}
          </Typography>
        );
      }
      return <span key={index}>{part}</span>;
    });
  };

  const smartTruncate = (text: string, maxLength: number) => {
    if (!maxLength) return text;

    const emMatch = /<em>.*?<\/em>/.exec(text);

    if (!emMatch) return truncateText(text, maxLength);

    const matchStart = emMatch.index;
    const halfLength = Math.floor(maxLength / 2);

    let start = Math.max(0, matchStart - halfLength);
    let end = Math.min(text.length, matchStart + halfLength);

    let truncated = text.slice(start, end);
    if (start > 0) truncated = "..." + truncated;
    if (end < text.length) truncated = truncated + "...";

    return truncated;
  };

  const processedText = maxLength ? smartTruncate(text, maxLength) : text;

  return <Typography variant="body1">{formatText(processedText)}</Typography>;
};

export default FormattedText;
