import Stack from "@mui/material/Stack";
import ModalLayout from "../../../components/ModalLayout";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import BulkUploadContactsButton from "./BulkUploadContactsButton";

const CSVUploadModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <ModalLayout open={open} toggle={() => setOpen(!open)}>
      <Stack direction={"column"} spacing={1} pt={3} pb={3}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant={"h2"} pb={2}>
            Upload list of contacts from a CSV file
          </Typography>
          <CloseIcon onClick={() => setOpen(!open)} />
        </Stack>
        <Stack direction={"column"} spacing={2}>
          <Typography variant="body1">
            The CSV file should have the following columns:
            <ul>
              <li>Addressable Name</li>
              <li>Surname</li>
              <li>First Name</li>
              <li>Email address (Must be unique)</li>
              <li>Organization</li>
              <li>Biography summary (Optional)</li>
              <li>Current roles / titles (Optional)</li>
            </ul>
          </Typography>
          <BulkUploadContactsButton toggle={() => setOpen(!open)} />
        </Stack>
      </Stack>
    </ModalLayout>
  );
};

export default CSVUploadModal;
