import Box from "@mui/material/Box";
import { useGetUserSelf } from "../query/user";
import { createContext, useEffect } from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import ls from "@livesession/sdk";
import { User } from "../types/User";

type AuthContextType = {
  user: User | null;
};

export const AuthContext = createContext<AuthContextType>({ user: null });

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: user } = useGetUserSelf();
  useEffect(() => {
    if (user) {
      ls.init("659d02b5.779a16ac");
      ls.identify({ email: user.email });
      ls.newPageView();
    }
  }, [user]);

  return (
    <>
      {user ? (
        <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
      ) : (
        <Box display="flex" justifyContent={"center"}>
          <Box position="absolute" sx={{ top: "50%" }}>
            <Stack direction={"column"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          </Box>
        </Box>
      )}
    </>
  );
};
