import Box from "@mui/material/Box";

import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Checkbox,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useContentTypes } from "../../../query/search";
import ContentWidget from "../../../components/ContentWidget";
import { Controller, useForm } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import { ContentType, SearchFilters } from "../../../types/Search";
import DateRangeSelect from "../../../components/DateRangeSelect";

type PersonCareerProps = {
  personId: string;
  contentTypes: ContentType[] | undefined;
};

const PersonActivityContainer = ({ personId }: { personId: string }) => {
  const { data: contentTypes, isLoading } = useContentTypes();

  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent={"center"}>
          <Box position="absolute" sx={{ top: "60%" }}>
            <Stack direction={"column"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          </Box>
        </Box>
      ) : null}
      {contentTypes && (
        <PersonActivity personId={personId} contentTypes={contentTypes} />
      )}
    </>
  );
};

const PersonActivity = ({ personId, contentTypes }: PersonCareerProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("xl"));
  const { control, setValue, watch } = useForm<SearchFilters>({
    defaultValues: {
      content_type: contentTypes ? contentTypes.map((ct) => ct.id) : [],
      q: "",
      date_from: "",
      date_to: "",
      exact_match: true,
    },
  });

  const handleSelectContentType = (
    e: React.ChangeEvent<HTMLInputElement>,
    contentTypeId: string,
  ) => {
    if (e.target.checked) {
      setValue("content_type", [
        ...(watch("content_type") || []),
        contentTypeId,
      ]);
    } else {
      setValue(
        "content_type",
        watch("content_type")?.filter((id) => id !== contentTypeId),
      );
    }
  };
  return (
    <>
      <Stack direction="column" spacing={1}>
        <Stack justifyContent={"space-between"} direction={"row"}>
          <Typography variant="h2">Recent Activity</Typography>
          <Box display="flex" gap={2}>
            <Controller
              name="q"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  id="outlined-basic"
                  placeholder="Search for content"
                  name="search"
                  variant="outlined"
                  sx={{
                    minWidth: "304px",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </Stack>
        <Box width="20%">
          <DateRangeSelect
            setValue={setValue}
            dateFrom={watch("date_from")}
            dateTo={watch("date_to")}
          />
        </Box>
        <Grid container spacing={2} py={3}>
          <Grid item xs={matches ? 2 : 4} sx={{ pl: "0 !important" }}>
            <Box
              px={2}
              py={2}
              sx={{
                backgroundColor: "secondary.main",
                borderRadius: "8px",
              }}
            >
              <Box
                pb={2}
                display={"flex"}
                alignItems={"center"}
                borderBottom={1}
                borderColor="divider"
              >
                <Typography variant="h3">Filter Activity</Typography>
              </Box>
              {contentTypes?.map((contentType) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  py={1}
                  key={contentType.id}
                >
                  <Checkbox
                    onChange={(e) => handleSelectContentType(e, contentType.id)}
                    checked={watch("content_type")?.includes(contentType.id)}
                  />
                  <Typography variant="h4">{contentType.name}</Typography>
                </Stack>
              ))}
            </Box>
          </Grid>
          <Grid item xs={matches ? 10 : 8}>
            <ContentWidget filter={watch()} selectedPersonId={personId} />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default PersonActivityContainer;
