import { Grid, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { PersonDetail } from "../../../types/Person";
import { useGetPersonFromParlyAPI } from "../../../query/person";
import ConstituencyMap from "./ConstituencyMap";
import GridCell from "./GridCell";

type PersonOverviewProps = {
  person: PersonDetail;
};

const ParlypubMapWrapper = ({ parly_uid }: { parly_uid: string }) => {
  const { data: personParlyData } = useGetPersonFromParlyAPI(parly_uid);

  return (
    <>
      {personParlyData?.value?.latestHouseMembership?.membershipFromId && (
        <Grid item xs={12} py={2}>
          <Typography variant="h2" py={2}>
            Constituency map
          </Typography>
          <ConstituencyMap
            constituencyId={
              personParlyData?.value?.latestHouseMembership?.membershipFromId
            }
          />
        </Grid>
      )}
    </>
  );
};

const PersonConstituency = ({ person }: PersonOverviewProps) => {
  return (
    <>
      <Stack direction="column">
        <Grid container spacing={4}>
          <Grid item xs={10} py={2}>
            <Typography variant="h2">Constituency Details</Typography>
          </Grid>

          <GridCell
            title="Constituency Phone No."
            content={person?.constituency_phone_number}
            cellSize={4}
          />

          <GridCell
            title="Constituency Address"
            content={person?.constituency_address?.split("\n")}
            cellSize={4}
          />

          {person.parly_uid && person?.organization === "HoC" ? (
            <ParlypubMapWrapper parly_uid={person.parly_uid} />
          ) : (
            <Grid item xs={12} py={2}>
              <Typography variant="h2" py={2}>
                Constituency
              </Typography>
              <Typography variant="h3">
                {person?.constituency || "No constituency data available"}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Stack>
    </>
  );
};

export default PersonConstituency;
