import { Box, Typography } from "@mui/material";

type CounterBoxProps = {
  count: number;
  disabled?: boolean;
};

const CounterBox = ({ count, disabled }: CounterBoxProps) => {
  return (
    <Box
      sx={{
        borderRadius: "50%",
        minWidth: "20px",
        alignContent: "center",
        justifyContent: "center",
        height: "20px",
        display: disabled ? "none" : "block",
        background: (theme) => theme.palette.primary.light,
      }}
    >
      <Typography sx={{ fontSize: "12px", color: "white", fontWeight: 700 }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          {count}
        </Box>
      </Typography>
    </Box>
  );
};

export default CounterBox;
