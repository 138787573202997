import Box from "@mui/material/Box";
import NoteModal from "../../components/NoteModal";
import {
  useCreateNoteMutation,
  useCreateNotesBulkMutation,
  useGetNote,
  useUpdateNoteMutation,
} from "../../query/note";
import { CreateNoteFormType } from "../../types/Notes";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

export interface NoteModalBaseProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface UpdateNoteModalProps extends NoteModalBaseProps {
  noteId: string;
}

interface CreateNoteModalProps extends NoteModalBaseProps {
  personId: string;
}

interface CreateBulkNoteModalProps extends NoteModalBaseProps {
  personIds: string[];
  setSelected?: (selected: string[]) => void;
}

export const NoteUpdateModalContainer = ({
  noteId,
  ...rest
}: UpdateNoteModalProps) => {
  const { data } = useGetNote(noteId);
  const { mutate: updater } = useUpdateNoteMutation();
  const header = "Edit engagement";
  const handleSubmitApi = (data: CreateNoteFormType) => {
    const inputObject = {
      title: data.note_title,
      note_type: data.note_type,
      text: data.note_content,
      attachments: data.attachments,
    };
    updater(
      { note_id: noteId, data: inputObject },
      {
        onSuccess: () => {
          rest.setOpen(false);
        },
      },
    );
  };

  return (
    <>
      {data ? (
        <NoteModal
          {...rest}
          note={data}
          handleSubmitApi={handleSubmitApi}
          header={header}
        />
      ) : null}
    </>
  );
};

export const NoteCreateModalContainer = ({
  personId,
  ...rest
}: CreateNoteModalProps) => {
  const { mutate } = useCreateNoteMutation();
  const header = "Add engagement";
  const handleSubmitApi = (inputData: CreateNoteFormType) => {
    const inputObject = {
      title: inputData.note_title,
      People_UID: personId,
      note_type: inputData.note_type,
      text: inputData.note_content,
      attachments: inputData.attachments,
    };
    mutate(inputObject, {
      onSuccess: () => {
        rest.setOpen(false);
      },
    });
  };

  return (
    <NoteModal {...rest} handleSubmitApi={handleSubmitApi} header={header} />
  );
};

export const NoteBulkCreateModalContainer = ({
  personIds,
  ...rest
}: CreateBulkNoteModalProps) => {
  const { mutate, isPending } = useCreateNotesBulkMutation();
  const header = `Add engagement to ${personIds.length} people`;
  const handleSubmitApi = (inputData: CreateNoteFormType) => {
    const inputObject = {
      title: inputData.note_title,
      People_UID: personIds,
      note_type: inputData.note_type,
      text: inputData.note_content,
      attachments: inputData.attachments,
    };
    mutate(inputObject, {
      onSuccess: () => {
        rest.setOpen(false);
      },
    });
  };
  return (
    <>
      {isPending ? (
        <Box display="flex" justifyContent={"center"}>
          <Box position="absolute" sx={{ top: "50%" }}>
            <Stack direction={"column"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          </Box>
        </Box>
      ) : null}
      <NoteModal {...rest} handleSubmitApi={handleSubmitApi} header={header} />
    </>
  );
};
