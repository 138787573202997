import { useMemo } from "react";
import { OrganisationsFilterItem } from ".";
import { Appg, Committee, Person } from "../../types/Person";

import {
  replaceDotsWithUnderscores,
  replaceUnderscoresWithDots,
} from "./utils";
import { LocalStorageKey, StorageService } from "../../services/StorageService";

const DRAWER_ORGS = ["HoC", "HoL", "NIASSEMBLY", "Senedd", "SCOTPARL", "SPADS"];

export const useOrganisationsFilterData = (
  persons: Person[],
  committees: Committee[],
  crossPartyGroups: Appg[],
) => {
  const organisationsFilterData = useMemo<
    OrganisationsFilterItem[] | undefined
  >(() => {
    if (!persons || !committees || !crossPartyGroups) return undefined;
    const houseMap: Record<
      string,
      {
        house: string;
        id: string;
        cttees: { name: string; id: string }[];
        appgs: { name: string; id: string }[];
        parties: { name: string; id: string }[];
        specialAdvisers: { name: string; id: string }[];
      }
    > = {};

    if (persons?.length && committees && crossPartyGroups) {
      persons?.forEach((person) => {
        if (person.organization && DRAWER_ORGS.includes(person.organization)) {
          if (!houseMap[person.organization]) {
            houseMap[person.organization] = {
              house: person.organization,
              id: person.organization,
              cttees: [],
              appgs: [],
              parties: [],
              specialAdvisers: [],
            };
          }

          person.cttees?.forEach((ctteePerson) => {
            const committeeData = committees.find(
              (cttee) => cttee.id === ctteePerson.id,
            );
            if (
              committeeData &&
              person?.organization &&
              !houseMap[person?.organization].cttees.find(
                (cttee) => cttee.id === committeeData.id,
              )
            ) {
              houseMap[person.organization].cttees.push({
                name: committeeData.name,
                id: committeeData.id,
              });
            }
          });
          person.appgs?.forEach((appgPerson) => {
            const appgData = crossPartyGroups.find(
              (appg) => appg.id === appgPerson.id,
            );
            if (
              appgData &&
              person?.organization &&
              !houseMap[person?.organization].appgs.find(
                (appg) => appg.id === appgData.id,
              )
            ) {
              houseMap[person.organization].appgs.push({
                name: appgData.name,
                id: appgData.id,
              });
            }
          });
          if (
            person.party &&
            !houseMap[person.organization].parties.find(
              (party) => party.id === person.party,
            )
          ) {
            houseMap[person.organization].parties.push({
              name: person.party,
              id: person.party,
            });
          }
          if (
            person.organization === "SPADS" &&
            person.department &&
            !houseMap[person.organization].specialAdvisers.find(
              ({ id }) => id === person.department,
            )
          ) {
            houseMap[person.organization].specialAdvisers.push({
              name: person.department,
              id: person.department,
            });
          }
        }
      });
    }
    return Object.values(houseMap).sort((a, b) => {
      return DRAWER_ORGS.indexOf(a.id) - DRAWER_ORGS.indexOf(b.id);
    });
  }, [committees, crossPartyGroups, persons]);

  return organisationsFilterData;
};

export const useGetDefaultFilters = (
  organisationsFilterData?: OrganisationsFilterItem[],
  initialFilter?: string,
  reset?: boolean,
) => {
  const drawerState = StorageService.Get(LocalStorageKey.DrawerState);

  const defaultFilters = useMemo(() => {
    if (!organisationsFilterData) return undefined;
    if (drawerState && !initialFilter) {
      return JSON.parse(drawerState);
    }

    const mapping: Record<string, Record<string, boolean>> = {};
    organisationsFilterData.forEach((organisation) => {
      mapping[organisation.id] = {
        [organisation.id]:
          initialFilter && initialFilter === organisation.id ? true : false,
      };

      organisation.cttees?.forEach((cttee) => {
        mapping[organisation.id][replaceDotsWithUnderscores(cttee.id)] = false;
      });

      organisation.appgs?.forEach((appg) => {
        mapping[organisation.id][replaceDotsWithUnderscores(appg.id)] = false;
      });

      organisation.parties?.forEach((party) => {
        mapping[organisation.id][replaceDotsWithUnderscores(party.id)] = false;
      });

      organisation.specialAdvisers?.forEach((adviser) => {
        mapping[organisation.id][replaceDotsWithUnderscores(adviser.id)] =
          false;
      });
    });
    StorageService.Set(LocalStorageKey.DrawerState, JSON.stringify(mapping));

    return mapping;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationsFilterData, initialFilter, reset, drawerState]);

  return defaultFilters;
};

export const useFilteredPersons = (
  persons: Person[],
  filterData?: Record<string, Record<string, boolean>>,
) => {
  const personsData = useMemo(() => {
    if (!filterData) return persons;
    const onlyChecked = Object.entries(filterData).filter(([key, value]) =>
      Object.values(value).some((v) => v === true),
    );
    if (!onlyChecked.length) return persons;
    const checkedFilters = Object.fromEntries(onlyChecked);

    const updatedOnlyChecked = replaceUnderscoresWithDots(checkedFilters);

    const filteredResults = persons?.filter((person) => {
      const houseCheck =
        updatedOnlyChecked[person?.organization]?.[person?.organization];

      const partyCheck = person.party
        ? updatedOnlyChecked[person?.organization]?.[person.party]
        : undefined;

      const committeeCheck = person.cttees?.some(
        (cttee) => updatedOnlyChecked[person?.organization]?.[cttee.id],
      );
      const appgCheck = person.appgs?.some(
        (appg) => updatedOnlyChecked[person?.organization]?.[appg.id],
      );

      const specialAdviserCheck = person.department
        ? updatedOnlyChecked[person?.organization]?.[person?.department]
        : undefined;

      return (
        houseCheck ||
        committeeCheck ||
        appgCheck ||
        partyCheck ||
        specialAdviserCheck
      );
    });

    return filteredResults;
  }, [filterData, persons]);
  return personsData;
};
