import {
  UseQueryResult,
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { ATTACHMENTS } from "./constants";
import {
  getAttachment,
  uploadFile,
  deleteAttachment,
} from "../services/Attachments";
import { Attachment } from "../types/Attachment";

export const useCreateAttachmentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: uploadFile,
    onSuccess: (result) => {
      queryClient.invalidateQueries({ queryKey: [ATTACHMENTS] });
      queryClient.setQueryData([ATTACHMENTS, result.id], result);
    },
  });
};

export function useAttachment(id: string): UseQueryResult<Attachment> {
  return useQuery({
    queryKey: [ATTACHMENTS, id],
    queryFn: () => getAttachment(id),
    enabled: true,
    retry: true,
    placeholderData: keepPreviousData,
  });
}

export const useDeleteAttachmentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteAttachment,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ATTACHMENTS] });
    },
  });
};
