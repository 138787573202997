import { Button } from "@mui/material";
import { useRef } from "react";

import { useBulkUploadContacts } from "../../../query/person";

const BulkUploadContactsButton = ({ toggle }: { toggle: () => void }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { mutate: bulkUploadContacts } = useBulkUploadContacts();

  const handleClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const handleChange = async (file: File | null) => {
    if (file) {
      bulkUploadContacts(file, {
        onSuccess: () => {
          toggle();
        },
      });
    }
  };
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        sx={{ maxWidth: 180 }}
        size="small"
      >
        Upload from CSV
      </Button>
      <input
        onChange={({ target: { files } }) => {
          handleChange(files?.[0] || null);
        }}
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
      />
    </>
  );
};

export default BulkUploadContactsButton;
