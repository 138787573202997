import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import useColors from "../containers/Lists/colors";
import { useCreateListMutation } from "../query/list";
import CloseIcon from "@mui/icons-material/Close";
import ModalLayout from "./ModalLayout";

type CreateListModalProps = {
  open: boolean;
  toggle: (open: boolean) => void;
};

const CreateListModal = ({ open, toggle }: CreateListModalProps) => {
  const { data: colors } = useColors();
  const { mutate: createList } = useCreateListMutation();
  const { register, watch, handleSubmit } = useForm({
    defaultValues: {
      list_name: "",
      category_name: "",
      color: "",
    },
  });
  const onSubmit = () => {
    const { category_name, list_name, color } = watch();
    createList(
      { category: category_name, list_name, color },
      {
        onSuccess: () => {
          toggle(false);
        },
      },
    );
  };
  return (
    // <Modal open={open} onClose={toggle}>
    //   <Box
    //     sx={{
    //       position: "absolute",
    //       top: "50%",
    //       left: "50%",
    //       transform: "translate(-50%, -50%)",
    //       bgcolor: "background.paper",
    //       px: 3,
    //       py: 1,
    //       width: 480,
    //       borderRadius: 2,
    //     }}
    //   >
    <ModalLayout open={open} toggle={toggle}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} pt={3}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant={"h2"} pb={2}>
              Create a new List
            </Typography>
            <CloseIcon onClick={() => toggle(false)} />
          </Stack>
          <TextField
            {...register("list_name", { required: true, maxLength: 100 })}
            required
            size="small"
            id="outlined-required"
            label="List name"
            placeholder="Enter list name"
          />
          <TextField
            {...register("category_name", { maxLength: 100 })}
            required
            size="small"
            id="outlined-required"
            label="Category name"
            placeholder="Enter category name"
          />
          <FormControl size="small">
            <InputLabel>Color</InputLabel>
            <Select {...register("color", { required: true })} label={"Color"}>
              {colors?.map((dataItem) => (
                <MenuItem key={dataItem.hex} value={dataItem.hex}>
                  <Stack direction={"row"} gap={1}>
                    <Box
                      width={16}
                      height={16}
                      sx={{
                        backgroundColor: dataItem?.hex,
                        borderRadius: "4px",
                      }}
                    />
                    {dataItem.name}
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            py={2}
            gap={1}
            sx={{
              borderTop: 1,
              borderColor: "divider",
            }}
          >
            <Button variant="outlined" onClick={() => toggle}>
              Cancel{" "}
            </Button>
            <Button variant="contained" type="submit">
              Confirm
            </Button>
          </Box>
        </Stack>
      </form>
    </ModalLayout>
  );
};
export default CreateListModal;
