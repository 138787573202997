import { API_PATHS } from "../constants";
import { Note } from "../types/Notes";
import { Member, MemberBio } from "../types/ParlyApi";
import {
  Appg,
  Committee,
  Person,
  PersonDetail,
  PersonInfo,
  PersonInfoInput,
  PersonInput,
  PersonSearchFilters,
  PersonStaff,
} from "../types/Person";
import { PaginatedResponse, ParlyAPIResponse } from "../types/utils";
import { HttpMethod, secureFileDownload, secureRequest } from "./ApiService";
import { getUrlFromFilters } from "./utils";
import { GeoJsonObject } from "geojson";

export const getPersons = async (
  offset: number = 0,
  limit: number = 25,
  order: string = "id",
  filters: PersonSearchFilters = {},
  ids?: string[],
): Promise<PaginatedResponse<Person>> => {
  const searchParams = new URLSearchParams();
  searchParams.set("order", order);
  searchParams.set("limit", String(limit));
  searchParams.set("offset", String(offset));
  if (ids && ids.length) {
    ids.forEach((id) => searchParams.append("id", String(id)));
  }
  const filterSearchString = getUrlFromFilters<PersonSearchFilters>(filters);
  const url = `${API_PATHS.PERSONS}?${searchParams.toString()}${filterSearchString ? "&" + filterSearchString : ""}`;
  return await secureRequest(HttpMethod.Get, url, {});
};

export const getPersonsElected = async (): Promise<
  PaginatedResponse<Person>
> => {
  return await secureRequest(HttpMethod.Get, API_PATHS.PERSONS_ELECTED, {});
};

export const getPersonsAll = async (): Promise<PaginatedResponse<Person>> => {
  return await secureRequest(HttpMethod.Get, API_PATHS.PERSONS_ALL, {});
};

export const getContactsAll = async (): Promise<PaginatedResponse<Person>> => {
  return await secureRequest(HttpMethod.Get, API_PATHS.CONTACTS_ALL, {});
};

export const createPerson = async (data: PersonInput): Promise<Person> => {
  return await secureRequest(HttpMethod.Post, API_PATHS.PERSONS, {
    data: data,
  });
};

export const getPerson = async (id: string): Promise<PersonDetail> => {
  return await secureRequest(HttpMethod.Get, API_PATHS.PERSONS + "/" + id, {});
};

export const getPersonsSearch = async (
  offset: number = 0,
  limit: number = 25,
  order: string = "id",
  q?: string,
): Promise<PaginatedResponse<Person>> => {
  const searchParams = new URLSearchParams();
  searchParams.set("order", order);
  searchParams.set("limit", String(limit));
  searchParams.set("offset", String(offset));
  searchParams.set("q", String(q));
  return await secureRequest(
    HttpMethod.Get,
    API_PATHS.PERSONS_SEARCH + "/?" + searchParams,
    {},
  );
};

export const getCommittees = async (
  offset: number = 0,
  limit: number = 25,
): Promise<PaginatedResponse<Committee>> => {
  const searchParams = new URLSearchParams();
  searchParams.set("limit", String(limit));
  searchParams.set("offset", String(offset));
  const url = `${API_PATHS.COMMITTEES}/?${searchParams.toString()}`;
  return await secureRequest(HttpMethod.Get, url, {});
};

export const getAppgs = async (
  offset: number = 0,
  limit: number = 25,
): Promise<PaginatedResponse<Appg>> => {
  const searchParams = new URLSearchParams();
  searchParams.set("limit", String(limit));
  searchParams.set("offset", String(offset));
  const url = `${API_PATHS.APPGS}/?${searchParams.toString()}`;
  return await secureRequest(HttpMethod.Get, url, {});
};

export const getPersonInfo = async (id: string): Promise<PersonInfo> => {
  return await secureRequest(
    HttpMethod.Get,
    API_PATHS.PERSONS + "/" + id + "/info",
    {},
  );
};

type UpdatePersonInfoInput = {
  id: string;
  data: PersonInfoInput;
};

export const updatePersonInfo = async ({
  id,
  data,
}: UpdatePersonInfoInput): Promise<PersonInfo> => {
  return await secureRequest(
    HttpMethod.Patch,
    API_PATHS.PERSONS + "/" + id + "/info",
    { data },
  );
};

export const createPersonInfo = async ({
  id,
  data,
}: UpdatePersonInfoInput): Promise<PersonInfo> => {
  return await secureRequest(
    HttpMethod.Post,
    API_PATHS.PERSONS + "/" + id + "/info",
    { data },
  );
};

export const getPersonStaff = async (id: string): Promise<PersonStaff[]> => {
  return await secureRequest(
    HttpMethod.Get,
    API_PATHS.PERSONS + "/" + id + "/staff",
    {},
  );
};
export const getPersonNotes = async (id: string): Promise<Note[]> => {
  return await secureRequest(
    HttpMethod.Get,
    API_PATHS.PERSONS + "/" + id + "/notes",
    {},
  );
};

export const getPersonGeo = async (
  id: number,
): Promise<ParlyAPIResponse<GeoJsonObject> | null> => {
  const result = await secureRequest(
    HttpMethod.Get,
    API_PATHS.PARLY_PROXY + "/geo/" + id,
    {},
    true,
  );
  return { value: JSON.parse(result.value) };
};

export const getPersonFromParlyAPI = async (
  id?: string,
): Promise<ParlyAPIResponse<Member> | null> => {
  if (!id) return null;
  return await secureRequest(
    HttpMethod.Get,
    API_PATHS.PARLY_PROXY + "/members/" + id,
    {},
    true,
  );
};

export const getPersonBioFromParlyAPI = async (
  id?: string,
): Promise<ParlyAPIResponse<MemberBio> | undefined> => {
  if (!id) return undefined;
  const url = API_PATHS.PARLY_PROXY + "/members/" + id + "/bio";
  return await secureRequest(HttpMethod.Get, url, {}, true);
};

export const exportPersonsData = async (
  personIds: string[],
): Promise<Response> => {
  const url: string = `${API_PATHS.PERSONS}/export`;

  return await secureFileDownload(HttpMethod.Post, url, {
    data: { person_ids: personIds },
  });
};

export const bulkUploadContacts = async (file: File): Promise<string> => {
  const formData = new FormData();
  formData.append("file", file);
  return await secureRequest(HttpMethod.Post, API_PATHS.PERSONS_BULK_UPLOAD, {
    data: formData,
  });
};

export const deletePerson = async (id: string): Promise<string> => {
  return await secureRequest(
    HttpMethod.Delete,
    API_PATHS.PERSONS + "/" + id,
    {},
  );
};

export type UpdatePersonInput = {
  id: string;
  data: PersonInput;
};

export const updatePerson = async ({
  id,
  data,
}: UpdatePersonInput): Promise<Person> => {
  return await secureRequest(HttpMethod.Patch, API_PATHS.PERSONS + "/" + id, {
    data,
  });
};
