import {
  Box,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ContentWidgetContent } from "../types/Search";
import { formatDate } from "../services/utils";
import { useState } from "react";
import FormattedText from "./HighlightedText";

interface ContentCardProps {
  content: ContentWidgetContent;
}

const ContentCard = ({ content }: ContentCardProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [expanded, setExpanded] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      border={1}
      borderColor="divider"
      borderRadius="8px"
      sx={{ background: "white" }}
      py={2}
      px={3}
      key={`${content.content_type}-${content.data_key}`}
    >
      <Stack spacing={1.5} direction={"column"}>
        <Box
          gap={3}
          display="flex"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Box display="flex" alignItems={"center"}>
            <Box gap={2} display={"flex"} alignItems={"center"}>
              <Box
                component={"img"}
                src={content?.type?.img}
                width={"30px"}
                height="30px"
              ></Box>
              <Typography variant="subtitle2" color="text.secondary">
                {content?.type?.name}
              </Typography>
            </Box>
            <Typography color="text.secondary" variant="subtitle2" px={5}>
              {formatDate(content?.created_at)}
            </Typography>
          </Box>
          <IconButton
            aria-label="more"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                setExpanded(!expanded);
                handleClose();
              }}
            >
              {expanded ? "Collapse" : "Expand"}
            </MenuItem>
          </Menu>
        </Box>

        <Typography variant="h3">{content?.title}</Typography>
        <FormattedText
          text={content.text}
          maxLength={expanded ? undefined : 400}
        />
        {content.url && (
          <Link
            href={content.url}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ mt: 2, display: "inline-block" }}
          >
            <Typography variant="subtitle1" color="primary">
              Link
            </Typography>
          </Link>
        )}
        {/* <Typography variant="subtitle2" color="primary">
          {content?.contributions_count} contributions
        </Typography> */}
      </Stack>
    </Box>
  );
};

export default ContentCard;
