import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
} from "@tanstack/react-query";
import {
  CONTENT_TYPES,
  PARTIES,
  REGIONS,
  SEARCH_CONTENT,
  SEARCH_PERSON,
} from "./constants";
import {
  getContentForPersonSearch,
  getContentForSearch,
  getContentTypes,
  getParties,
  getPersonsForSearch,
  getRegions,
} from "../services/SearchService";
import { SearchFilters } from "../types/Search";
import { useMemo } from "react";

export function useRegions() {
  return useQuery({
    queryKey: [REGIONS],
    queryFn: () => getRegions(),
    enabled: true,
    retry: true,
  });
}

export function useParties() {
  return useQuery({
    queryKey: [PARTIES],
    queryFn: () => getParties(),
    enabled: true,
    retry: true,
  });
}

export function useContentTypes() {
  return useQuery({
    queryKey: [CONTENT_TYPES],
    queryFn: () => getContentTypes(),
    enabled: true,
    retry: true,
  });
}

export function usePersonsOmniSearch(
  limit: number = 20,
  order: string = "id",
  filters: SearchFilters = {},
) {
  const {
    data,
    isFetching,
    error,
    fetchNextPage,
    hasNextPage,
    isRefetching,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: [SEARCH_PERSON, filters],
    queryFn: ({ pageParam }) => {
      return getPersonsForSearch(pageParam, limit, order, filters);
    },
    enabled: true,
    retry: true,
    initialPageParam: 0,

    getNextPageParam: (lastPage, _) => {
      if (!lastPage.results.length) {
        return undefined;
      }
      if (lastPage.offset === 0) {
        return limit;
      }
      return lastPage.offset + limit;
    },
  });

  const flattenedData = useMemo(
    () =>
      data?.pages
        .flatMap((page) => page.results)
        .filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.id === item.id),
        ),
    [data],
  );

  return {
    data: flattenedData,
    isLoading,
    isFetching,
    isRefetching,
    error,
    isFetchingNextPage,
    loadMore: fetchNextPage,
    hasNextPage,
  };
}

export function useContentSearch(
  limit: number = 20,
  order: string = "id",
  person_id?: string,
  filters: SearchFilters = {},
) {
  const { data, isFetching, error, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: [SEARCH_CONTENT, person_id, filters],
      queryFn: ({ pageParam }) => {
        if (person_id) {
          return getContentForPersonSearch(
            pageParam,
            limit,
            order,
            person_id,
            filters,
          );
        }
        return getContentForSearch(pageParam, limit, order, filters);
      },
      enabled: true,
      retry: true,
      initialPageParam: 0,
      placeholderData: keepPreviousData,
      getNextPageParam: (lastPage, _) => {
        if (!lastPage.results.length) {
          return undefined;
        }
        if (lastPage.offset === 0) {
          return limit;
        }
        return lastPage.offset + limit;
      },
    });
  const flattenedData = useMemo(
    () =>
      data?.pages
        .flatMap((page) => page.results)
        .filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.data_key === item.data_key),
        ),
    [data],
  );
  return {
    data: flattenedData,
    isLoading: isFetching,
    error,
    loadMore: fetchNextPage,
    hasNextPage,
  };
}
