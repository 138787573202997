import {
  InputAdornment,
  TextField,
  Typography,
  Button,
  Stack,
  FormControlLabel,
  Switch,
  Grid,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import { Controller, useForm } from "react-hook-form";
import { SearchFilters } from "../types/Search";

import { useState } from "react";
import TuneIcon from "@mui/icons-material/Tune";
import FilterBox from "../containers/Search/SearchFilter";
import normalizeFilterBySearchType from "../containers/Search/utils";

type OmniSearchFilterProps = {
  setFilter: (filter: SearchFilters) => void;
  isPersonsSearch: boolean;
  setIsPersonsSearch: (isPersonsSearch: boolean) => void;
};

const OmniSearchFilter = ({
  setFilter,
  isPersonsSearch,
  setIsPersonsSearch,
}: OmniSearchFilterProps) => {
  const [filtersOpen, setFiltersOpen] = useState(false);

  const { control, handleSubmit, setValue, watch, getValues } =
    useForm<SearchFilters>({
      defaultValues: {
        party: [],
        region: [],
        gender: [],
        content_type: [],
        lists: [],
        date_from: "",
        date_to: "",
        q: "",
        exact_match: false,
      },
    });
  const onSubmit = (data: SearchFilters) => {
    const normalizedData = normalizeFilterBySearchType(data, isPersonsSearch);
    setFilter({
      ...normalizedData,
      date_from: normalizedData.date_from,
      date_to: normalizedData.date_to,
    });
  };

  const handleSubmitFormFromModal = () => {
    onSubmit(getValues());
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          pt={6}
          pb={4}
          px={5}
          direction={"column"}
          sx={{
            backgroundColor: "secondary.main",
            ".MuiSvgIcon-root": {
              color: "common.black",
            },
          }}
        >
          <Typography variant="h1">Search for Content</Typography>

          <Grid container mt={4} mb={2} maxHeight={"200px"}>
            <Grid item xs={12} md={6}>
              <Box display={"flex"} gap={2}>
                <Controller
                  name="q"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      id="outlined-basic"
                      placeholder="Search for content"
                      name="search"
                      variant="outlined"
                      sx={{
                        flex: 1,
                        minWidth: "170px",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  sx={{
                    minWidth: "100px",
                  }}
                >
                  Search
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display={"flex"} justifyContent={"end"}>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<TuneIcon />}
                  sx={{
                    borderColor: "divider",
                    color: "text.primary",
                  }}
                  onClick={() => setFiltersOpen(!filtersOpen)}
                >
                  Filter
                </Button>
              </Box>
            </Grid>
          </Grid>
          <FormControlLabel
            control={
              <Switch
                checked={watch("exact_match")}
                onChange={() => setValue("exact_match", !watch("exact_match"))}
              />
            }
            label="Exact Match"
          />
        </Stack>
        <FilterBox
          open={filtersOpen}
          setOpen={setFiltersOpen}
          isPersonsSearch={isPersonsSearch}
          setIsPersonsSearch={setIsPersonsSearch}
          setValue={setValue}
          control={control}
          watch={watch}
          handleSubmitForm={handleSubmitFormFromModal}
        />
      </form>
    </>
  );
};

export default OmniSearchFilter;
