import Card from "@mui/material/Card";
import { BioItem } from "../types/ParlyApi";
import { getOrgIconFromKey } from "./utils";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";

type CareerCardProps = {
  content: BioItem;
};

const CareerCard = ({ content }: CareerCardProps) => {
  const houseIcon =
    content.house === 1 ? getOrgIconFromKey("HoC") : getOrgIconFromKey("HoL");

  return (
    <Card variant="outlined" sx={{ maxWidth: 360, height: 148 }}>
      <Stack direction="column" spacing={1} padding={2}>
        <Stack direction="row">
          <SvgIcon inheritViewBox component={houseIcon as any} />
        </Stack>
        <Typography variant="h3">{content.name}</Typography>
        <Typography variant="subtitle2">{content.additionalInfo}</Typography>

        <Stack direction="row">
          <Typography variant="subtitle2">
            {new Date(content.startDate).toLocaleDateString("en-GB")}
          </Typography>
          <Typography variant="subtitle2">{"\u00A0-\u00A0"}</Typography>
          <Typography variant="subtitle2">
            {content.endDate
              ? new Date(content.endDate).toLocaleDateString("en-GB")
              : "Present"}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export default CareerCard;
