import Box from "@mui/material/Box";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";

import { useRef } from "react";
// import {
//   UseFormGetValues,
//   UseFormRegister,
//   UseFormSetValue,
// } from "react-hook-form";
// import { CreateNoteFormType } from "../types/Notes";

import { useCreateAttachmentMutation } from "../query/attachment";

import { Attachment } from "../types/Attachment";

// type FileInputComponentProps = {
//   register: UseFormRegister<CreateNoteFormType>;
//   setValue: UseFormSetValue<CreateNoteFormType>;
//   getValues: UseFormGetValues<CreateNoteFormType>;
// };
// FIXME: do the generic
const FileInputComponent = ({ register, setValue, getValues }: any) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { mutateAsync } = useCreateAttachmentMutation();

  const handleBoxClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };
  const handleChange = (browserFiles?: FileList | null) => {
    const listOfFiles: File[] = Array.prototype.slice.call(browserFiles);

    if (listOfFiles.length) {
      const promises: Promise<Attachment>[] = [];
      for (let i = 0; i < listOfFiles.length; i += 1) {
        promises.push(mutateAsync(listOfFiles[i]));
      }
      Promise.all(promises).then((results) => {
        setValue(
          "attachments",
          [...getValues("attachments"), ...results.map((result) => result.id)],
          { shouldDirty: true },
        );
      });
    }
  };

  return (
    <Box
      sx={{
        borderStyle: "dashed",
        borderColor: "divider",
      }}
      py={2}
      onClick={handleBoxClick}
    >
      <Stack direction={"column"} spacing={1} alignItems={"center"} p={2}>
        <UploadFileOutlinedIcon sx={{ width: 30, height: 30 }} />
        <Typography variant="h3">Click here to browse files</Typography>
        <Typography variant="subtitle2">
          Maximum upload file size: 2MB
        </Typography>
        <input
          {...register("attachments")}
          onChange={({ target: { files } }) => {
            handleChange(files);
          }}
          ref={fileInputRef}
          type="file"
          style={{ display: "none" }}
          multiple
        />
      </Stack>
    </Box>
  );
};

export default FileInputComponent;
