import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import { useContext, useEffect, useState } from "react";

import { useAppgs, useCommittees, usePersonsElected } from "../../query/person";
import CounterBox from "../../components/CounterBox";
import AddOrCreateListModal from "../../components/AddOrCreateListModal";
import PersonsTable from "./PersonsTable";
import PersonsDrawer from "./PersonsDrawer";

import { useForm } from "react-hook-form";
import { Person } from "../../types/Person";
import { PaginatedResponse } from "../../types/utils";
import { checkedCount, getSelectedEmails } from "./utils";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import {
  useFilteredPersons,
  useGetDefaultFilters,
  useOrganisationsFilterData,
} from "./hooks";
import { useListsAll } from "../../query/list";
import { ProfileList } from "../../types/List";
import { handlePersonsExport } from "../../services/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { NoteBulkCreateModalContainer } from "../Engagements/NotesModals";
import { AuthContext } from "../../context/AuthContext";
import { isFeatureEnabled } from "../../components/utils";
import { Features } from "../../types/User";
import { LocalStorageKey } from "../../services/StorageService";
import { StorageService } from "../../services/StorageService";
import { MRT_RowSelectionState } from "material-react-table";

export type OrganisationsFilterItem = {
  house: string;
  id: string;
  cttees: {
    name: string;
    id: string;
  }[];
  appgs: {
    name: string;
    id: string;
  }[];
  parties: {
    name: string;
    id: string;
  }[];
  specialAdvisers: {
    name: string;
    id: string;
  }[];
};

const PersonsContainer = () => {
  const { state } = useLocation();
  let initialFilter = state?.initialFilter;
  window.history.replaceState({}, "", window.location.pathname);

  const { data: persons, isLoading: personsLoading } = usePersonsElected();
  const { data: committees, isLoading: committeesLoading } = useCommittees(
    0,
    10000,
  );
  const { data: crossPartyGroups, isLoading: crossPartyGroupsLoading } =
    useAppgs(0, 10000);
  const { data: lists, isLoading: listsLoading } = useListsAll();

  const organisationsFilterData = useOrganisationsFilterData(
    persons?.results || [],
    committees?.results || [],
    crossPartyGroups?.results || [],
  );
  const [reset, setReset] = useState(false);

  const defaultFilters = useGetDefaultFilters(
    organisationsFilterData,
    initialFilter,
    reset,
  );

  return (
    <>
      {personsLoading ||
      !organisationsFilterData ||
      !defaultFilters ||
      committeesLoading ||
      crossPartyGroupsLoading ||
      listsLoading ? (
        <Box display="flex" justifyContent={"center"}>
          <Box position="absolute" sx={{ top: "50%" }}>
            <Stack direction={"column"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          </Box>
        </Box>
      ) : null}
      {persons && defaultFilters && organisationsFilterData && lists ? (
        <Persons
          persons={persons}
          defaultFilters={defaultFilters}
          organisationsFilterData={organisationsFilterData}
          lists={lists}
          setReset={setReset}
          reset={reset}
        />
      ) : null}
    </>
  );
};

type PersonsProps = {
  persons: PaginatedResponse<Person>;

  lists: PaginatedResponse<ProfileList>;
  defaultFilters: Record<string, Record<string, boolean>>;
  organisationsFilterData: OrganisationsFilterItem[];
  savedDrawerState?: Record<string, Record<string, boolean>> | undefined;
  setReset: (reset: boolean) => void;
  reset: boolean;
};

export type DrawerStateFilterType = {
  houses: string[];
  committees: string[];
  crossPartyGroups: string[];
  parties: string[];
  specialAdvisers: string[];
};

export const Persons = ({
  persons,
  defaultFilters,
  organisationsFilterData,
  setReset,
  reset,
}: PersonsProps) => {
  const { user } = useContext(AuthContext);
  const [selected, setSelected] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [listsModalOpen, setListsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setSelected(Object.keys(rowSelection));
  }, [rowSelection]);

  const navigate = useNavigate();

  const {
    control,
    watch,
    reset: formReset,
  } = useForm<Record<string, Record<string, boolean>>>({
    defaultValues: defaultFilters,
  });
  const filterData = watch();

  useEffect(() => {
    StorageService.Set(LocalStorageKey.DrawerState, JSON.stringify(filterData));
  }, [filterData]);

  useEffect(() => {
    formReset(defaultFilters);
  }, [defaultFilters, formReset]);

  const performReset = () => {
    StorageService.Remove(LocalStorageKey.DrawerState);
    setRowSelection({});
    setReset(!reset);
  };

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [action, setAction] = useState<number>(1);
  const [notesOpen, setNotesOpen] = useState<boolean>(false);

  const personsData = useFilteredPersons(persons.results, filterData);

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    switch (action) {
      case 1:
        handlePersonsExport(e, selected);
        break;
      case 2:
        setNotesOpen(true);
        break;
      case 3:
        navigate("/emails/create", {
          state: { personEmails: getSelectedEmails(selected, persons.results) },
        });
        break;
      case 4:
        setListsModalOpen(true);
        break;
    }
  };

  useEffect(() => {
    if (checkedCount(filterData) > 0) {
      setDrawerOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {persons?.results.length ? (
        <Box
          display="inline-flex"
          flexDirection={"row"}
          sx={{ overflow: "hidden" }}
        >
          <Stack direction="column">
            <Collapse in={drawerOpen} orientation="horizontal">
              {organisationsFilterData.length > 0 ? (
                <PersonsDrawer
                  toggle={setDrawerOpen}
                  open={drawerOpen}
                  data={organisationsFilterData}
                  control={control}
                  filterState={filterData}
                  reset={performReset}
                />
              ) : null}
            </Collapse>
          </Stack>

          <Stack direction="column">
            <Stack
              direction={"row"}
              py={4}
              borderBottom={1}
              borderColor={"divider"}
              alignItems={"center"}
              pr={4}
              pl={drawerOpen ? 8 : 1}
              minHeight={120}
              justifyContent={"space-between"}
            >
              <Stack direction={"row"} spacing={3}>
                {!drawerOpen ? (
                  <Box display={"block"}>
                    <Button
                      variant="contained"
                      onClick={() => setDrawerOpen(!drawerOpen)}
                      startIcon={<KeyboardTabIcon />}
                      size="small"
                      endIcon={
                        <CounterBox
                          count={checkedCount(filterData)}
                          disabled={checkedCount(filterData) === 0}
                        />
                      }
                    >
                      People Explorer
                    </Button>
                  </Box>
                ) : null}
                <Typography variant="h1">People</Typography>
              </Stack>
              <Stack direction="row" gap={3} alignItems={"center"}>
                <Typography variant="h3">Actions:</Typography>
                <Select
                  defaultValue={1}
                  onChange={(e) => setAction(e.target.value as number)}
                  disabled={selected.length === 0}
                  sx={{
                    minWidth: 280,
                  }}
                  size="small"
                >
                  <MenuItem value={1}>
                    <Typography>
                      Export data for {selected.length} selected
                    </Typography>
                  </MenuItem>
                  <MenuItem value={2}>
                    <Typography>
                      Add a note for {selected.length} selected
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    value={3}
                    disabled={!isFeatureEnabled(user, Features.EMAILING)}
                  >
                    <Typography>Email to {selected.length} selected</Typography>
                  </MenuItem>
                  <MenuItem value={4}>
                    <Typography>Add {selected.length} to a List</Typography>
                  </MenuItem>
                </Select>
                <Button
                  variant="contained"
                  disabled={selected.length === 0}
                  onClick={handleConfirm}
                >
                  Confirm selection
                </Button>
              </Stack>

              {/* <Stack direction="row" gap={3} alignItems={"center"}>
                <Box gap={2} display="flex">
                  <Button
                    variant="contained"
                    disabled={selected.length === 0}
                    startIcon={<DownloadIcon />}
                    onClick={(e) => handlePersonsExport(e, selected)}
                    endIcon={
                      <CounterBox
                        count={selected.length}
                        disabled={selected.length === 0}
                      />
                    }
                  >
                    Export selected to CSV
                  </Button>
                  <Button
                    variant="contained"
                    disabled={selected.length === 0}
                    endIcon={
                      <CounterBox
                        count={selected.length}
                        disabled={selected.length === 0}
                      />
                    }
                    onClick={() => setListsModalOpen(true)}
                    startIcon={
                      <Typography
                        variant="h2"
                        sx={{
                          color: selected.length === 0 ? "grey" : "white",
                        }}
                      >
                        +
                      </Typography>
                    }
                  >
                    Add selected to a list
                  </Button>
                </Box>
              </Stack> */}
            </Stack>

            <PersonsTable
              persons={personsData}
              setRowSelection={setRowSelection}
              rowSelection={rowSelection}
              drawerOpen={drawerOpen}
            />
          </Stack>
        </Box>
      ) : null}
      <AddOrCreateListModal
        people={selected}
        open={listsModalOpen}
        toggle={() => setListsModalOpen(!listsModalOpen)}
      />
      <NoteBulkCreateModalContainer
        personIds={selected}
        open={notesOpen}
        setOpen={setNotesOpen}
      />
    </Box>
  );
};

export default PersonsContainer;
