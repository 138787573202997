export enum Features {
  EMAILING = "emails",
}

export type User = {
  account_type: string;
  id: string;
  email: string;
  org_id?: string;
  role?: string;
  company?: string;
  last_name?: string;
  first_name?: string;
  job_title?: string;
  name?: string;
  features_enabled: Features[];
};
