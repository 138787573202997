import { Person, PersonEmail } from "../../types/Person";

interface Entity {
  type: string;
  name: string;
  id: string;
  parties?: EntityGroup;
  committees?: EntityGroup;
  cross_party_groups?: EntityGroup;
  opened?: boolean;
}

interface EntityGroup {
  type: string;
  name: string;
  id: string;
  opened?: boolean;
  data?: Entity[];
}

export const replaceUnderscoresWithDots = (
  drawerState: Record<string, Record<string, boolean>>,
) => {
  const updatedDrawerState: Record<string, Record<string, boolean>> = {};
  for (const key in drawerState) {
    updatedDrawerState[key] = { ...drawerState[key] };

    for (const subKey in drawerState[key]) {
      if (drawerState[key][subKey] === true) {
        const newKey = subKey.replace(/__/g, ".");
        updatedDrawerState[key][newKey] = drawerState[key][subKey];
      }
    }
  }
  return updatedDrawerState;
};

export const replaceDotsWithUnderscores = (id: string) => {
  return id.replace(/\./g, "__");
};

export const checkedCount = (
  filterData: Record<string, Record<string, boolean>>,
): number => {
  return Object.values(filterData).flatMap((org) =>
    Object.values(org).filter(Boolean),
  ).length;
};

export const getOrgNameFromKey = (org: string) => {
  if (org === "HoC") {
    return "House of Commons";
  }
  if (org === "HoL") {
    return "House of Lords";
  }
  if (org === "SPADS") {
    return "Special Advisers";
  }
  if (org === "NIASSEMBLY") {
    return "Northern Ireland Assembly";
  }
  if (org === "SCOTPARL") {
    return "Scottish Parliament";
  }
  if (org === "Senedd") {
    return "Senedd";
  } else {
    return "N/A";
  }
};

export const isFromParlyApi = (org?: string) => {
  return org ? ["HoC", "HoL"].includes(org) : false;
};

export const getSelectedEmails = (
  selected: string[],
  filteredPersons?: Person[],
): PersonEmail[] | [] => {
  if (!filteredPersons) return [];
  return filteredPersons
    .filter((person) => selected.includes(person.id))
    .map((person) => {
      return { id: person.id, email_address: person.email_address };
    });
};

export const getCountForCollapsable = (
  orgId: string,
  filterState: Record<string, Record<string, boolean>>,
) => {
  let count = 0;
  if (filterState[orgId]) {
    Object.entries(filterState[orgId]).forEach(([key, value]) => {
      if (value === true) count++;
    });
  }
  return count;
};
