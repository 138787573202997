import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import HookedFormField from "../../../components/HookedFormField";
import { PersonInfoInput, PersonInput } from "../../../types/Person";
import Grid from "@mui/material/Grid";
import { usePersonsMutation } from "../../../query/person";
import { Navigate } from "react-router-dom";

import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import HookedFormSelectField from "../../../components/HookedSelectField";
import { PATHS } from "../../../router";
import ModalLayout from "../../../components/ModalLayout";

const schema = yup
  .object({
    email_address: yup.string().email("Should be a valid email").required(),
    first_name: yup.string().required("This field is required"),
    name: yup.string().required("This field is required"),
    surname: yup.string().required("This field is required"),
    current_roles_titles: yup.string(),
    office_phone_number: yup.string(),
    gender: yup
      .string()
      .oneOf(["M", "F"], "Available choices: Male and Female"),
    organization: yup.string().required("This field is required"),
    bio_summary: yup.string().max(250, "Must be less than 250 characters"),
  })
  .required();

type PersonCreateModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const PersonCreateModal = ({ open, setOpen }: PersonCreateModalProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { mutate, isSuccess } = usePersonsMutation();
  const onSubmit: SubmitHandler<PersonInput & PersonInfoInput> = (data) => {
    mutate(data, {
      onSuccess: (result) => {
        setOpen(false);
      },
    });
  };

  return (
    <ModalLayout open={open} toggle={() => setOpen(!open)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isSuccess && <Navigate to={PATHS.CONTACTS} />}
        <Stack direction={"column"} spacing={1} pt={3} pb={6}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant={"h2"} pb={2} pl={2}>
              Create custom contact
            </Typography>
            <CloseIcon onClick={() => setOpen(!open)} />
          </Stack>
          <Grid container columnSpacing={2} rowSpacing={2} pr={2}>
            <Grid item md={6}>
              <HookedFormField
                control={control}
                error={errors.first_name}
                label="First Name"
                name="first_name"
                helperText="Input the first name."
                required={true}
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <HookedFormField
                control={control}
                error={errors.surname}
                label="Surname"
                name="surname"
                helperText="Input the surname"
                required={true}
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <HookedFormField
                control={control}
                error={errors.name}
                label="Name to address"
                name="name"
                helperText="Input the name by which to address the contact"
                required={true}
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <HookedFormSelectField
                control={control}
                error={errors.gender}
                label="Gender"
                name="gender"
                helperText="Select gender"
                required
                select
                fullWidth
              >
                <MenuItem value="M" key="M">
                  Male
                </MenuItem>
                <MenuItem value="F" key="F">
                  Female
                </MenuItem>
              </HookedFormSelectField>
            </Grid>
            <Grid item md={6}>
              <HookedFormField
                control={control}
                error={errors.email_address}
                label="Email address"
                name="email_address"
                helperText={"Contact's email"}
                required={true}
                fullWidth
              />
            </Grid>

            <Grid item md={6}>
              <HookedFormField
                control={control}
                error={errors.office_phone_number}
                label="Phone"
                name="office_phone_number"
                helperText="Input phone number"
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <HookedFormField
                control={control}
                error={errors.organization}
                label="Organization"
                name="organization"
                helperText="Input associated organization"
                required
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <HookedFormField
                control={control}
                error={errors.current_roles_titles}
                label="Current Role"
                name="current_roles_titles"
                helperText="Current role"
                fullWidth
              />
            </Grid>
            <Grid item md={12}>
              <HookedFormField
                control={control}
                error={errors.bio_summary}
                label="Description"
                name="bio_summary"
                helperText="Short description (max 250 char.)"
                fullWidth
                multiline
                rows={2}
              />
            </Grid>
          </Grid>
        </Stack>
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          py={2}
          gap={1}
          sx={{
            borderTop: 1,
            borderColor: "divider",
          }}
        >
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel{" "}
          </Button>
          <Button variant="contained" type="submit">
            Confirm
          </Button>
        </Box>
      </form>
    </ModalLayout>
  );
};

export default PersonCreateModal;
