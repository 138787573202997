import Box from "@mui/material/Box";
import { usePersonBio } from "../../../query/person";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid, Typography, Divider } from "@mui/material";
import { BioItem } from "../../../types/ParlyApi";
import CareerCard from "../../../components/CareerCard";

type PersonCareerProps = {
  parly_uid: string;
};

const PersonCareer = ({ parly_uid }: PersonCareerProps) => {
  const { data: bio, isLoading } = usePersonBio(parly_uid.replace("HoL_", ""));
  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent={"center"}>
          <Box position="absolute" sx={{ top: "50%" }}>
            <Stack direction={"column"} alignItems={"center"}>
              <CircularProgress />
            </Stack>
          </Box>
        </Box>
      ) : null}
      {bio && bio.value ? (
        <Stack direction="column" spacing={6}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h2">Current Parliamentary Career</Typography>
            </Grid>
            {bio.value.representations
              .filter((item) => !item.endDate)
              .map((content: BioItem) => (
                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <Typography variant="h4">Representation</Typography>
                    <CareerCard content={content} />
                  </Stack>
                </Grid>
              ))}
            {bio.value.governmentPosts
              .filter((item) => !item.endDate)
              .map((content: BioItem) => (
                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <Typography variant="h4">Government Post</Typography>
                    <CareerCard content={content} />
                  </Stack>
                </Grid>
              ))}
            {bio.value.oppositionPosts
              .filter((item) => !item.endDate)
              .map((content: BioItem) => (
                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <Typography variant="h4">Opposition Post</Typography>
                    <CareerCard content={content} />
                  </Stack>
                </Grid>
              ))}
            {bio.value.committeeMemberships
              .filter((item) => !item.endDate)
              .map((content: BioItem) => (
                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <Typography variant="h4">Committee Membership</Typography>
                    <CareerCard content={content} />
                  </Stack>
                </Grid>
              ))}
          </Grid>
          <Divider />
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h2">
                Previous Parliamentary Career
              </Typography>
            </Grid>
            {bio.value.representations
              .filter((item) => item.endDate)
              .map((content: BioItem) => (
                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <Typography variant="h4">Representation</Typography>
                    <CareerCard content={content} />
                  </Stack>
                </Grid>
              ))}
            {bio.value.governmentPosts
              .filter((item) => item.endDate)
              .map((content: BioItem) => (
                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <Typography variant="h4">Government Post</Typography>
                    <CareerCard content={content} />
                  </Stack>
                </Grid>
              ))}
            {bio.value.oppositionPosts
              .filter((item) => item.endDate)
              .map((content: BioItem) => (
                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <Typography variant="h4">Opposition Post</Typography>
                    <CareerCard content={content} />
                  </Stack>
                </Grid>
              ))}
            {bio.value.committeeMemberships
              .filter((item) => item.endDate)
              .map((content: BioItem) => (
                <Grid item xs={4}>
                  <Stack spacing={2}>
                    <Typography variant="h4">Committee Membership</Typography>
                    <CareerCard content={content} />
                  </Stack>
                </Grid>
              ))}
          </Grid>
        </Stack>
      ) : null}
    </>
  );
};

export default PersonCareer;
