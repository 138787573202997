import { useRef } from "react";
import { useAttachment } from "../query/attachment";
import { downloadAttachment } from "../services/Attachments";
import { Box, Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type AttachmentLinkWidgetProps = {
  attachmentId: string;
  onDelete?: () => void;
};

const AttachmentLinkWidget = ({
  attachmentId,
  onDelete,
}: AttachmentLinkWidgetProps) => {
  const parsedAttachmentId = attachmentId.split("/")[0];
  const { data } = useAttachment(parsedAttachmentId);
  const ref = useRef<HTMLAnchorElement>(null);
  const handleFileDownload = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const attachmentFile = await downloadAttachment(parsedAttachmentId);
    const blob = await attachmentFile.blob();
    const newBlob = new Blob([blob]);
    const url = window.URL.createObjectURL(newBlob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${data?.filename}`);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete();
    }
  };
  return (
    <Box>
      {data?.filename ? (
        <Box display={"flex"} justifyContent={"space-between"}>
          <Link
            variant="body2"
            ref={ref}
            onClick={handleFileDownload}
            href={`#`}
          >
            {data.filename}
          </Link>
          {onDelete ? <CloseIcon onClick={handleDelete} /> : null}
        </Box>
      ) : null}
    </Box>
  );
};

export default AttachmentLinkWidget;
