import { Helmet } from "react-helmet";
import { AuthContext } from "../context/AuthContext";
import { useContext, useEffect } from "react";

const HubspotWidgetComponent = () => {
  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (user?.email) {
      // @ts-ignore
      let _hsq = window._hsq || [];
      _hsq.push(["identify", { email: user.email }]);
    }
  }, [user]);
  return (
    <Helmet>
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/20219572.js"
      ></script>
    </Helmet>
  );
};

export default HubspotWidgetComponent;
