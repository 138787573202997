import { ReactComponent as hocIcon } from "../assets/images/logos/Crowned_Portcullis_redesign_2018.svg";
import { ReactComponent as holIcon } from "../assets/images/logos/house_of_lords.svg";
import { ReactComponent as scotIcon } from "../assets/images/logos/scottish_parliament_logo.svg";
import { ReactComponent as seneddIcon } from "../assets/images/logos/SeneddLogo.svg";
import { ReactComponent as NIAIcon } from "../assets/images/logos/NI_assembly.svg";

import { LocalStorageKey, StorageService } from "../services/StorageService";
import { Features, User } from "../types/User";

export const getOrgIconFromKey = (org: string) => {
  switch (org) {
    case "HoC":
      return hocIcon;
    case "HoL":
      return holIcon;
    case "SCOTPARL":
      return scotIcon;
    case "Senedd":
      return seneddIcon;
    case "NIASSEMBLY":
      return NIAIcon;
    default:
      return undefined;
  }
};

export enum EXTERNAL_PATHS {
  OWN_ACCOUNT = "https://app.polimonitor.com/user_details/",
  LOGOUT = "https://app.polimonitor.com/signout/",
  TWITTER_DASHBOARD = "https://app.polimonitor.com/twitter/dashboard/",
  PUBLICATIONS = "https://app.polimonitor.com/parlypub/dashboard/",
  BROADCAST_DASHBOARD = "https://app.polimonitor.com/broadcast/dashboard/",
  BRIEFINGS_DASHBOARD = "https://app.polimonitor.com/parlypub/briefings/",
}

export const redirectToExternal = (
  location: EXTERNAL_PATHS,
  event?: React.MouseEvent<HTMLElement>,
): void => {
  if (event) event.preventDefault();
  window.open(location, "_self");
};

export const logOut = (event?: React.MouseEvent<HTMLElement>): void => {
  if (event) event.preventDefault();
  StorageService.Remove(LocalStorageKey.Jwt);
  window.open(EXTERNAL_PATHS.LOGOUT, "_self");
};

export const isFeatureEnabled = (user: User | null, feature: Features) => {
  if (!user) return false;
  return user.features_enabled.includes(feature);
};
