import {
  ContentType,
  Party,
  Region,
  SearchContentType,
  SearchFilters,
} from "../types/Search";
import { HttpMethod, secureRequest } from "./ApiService";
import { API_PATHS } from "../constants";
import { PaginatedResponse } from "../types/utils";
import { PersonOmniSearchResult } from "../types/Person";
import { getUrlFromFilters } from "./utils";

export const getRegions = async (): Promise<Region[]> => {
  return await secureRequest(HttpMethod.Get, API_PATHS.REGIONS, {});
};

export const getParties = async (): Promise<Party[]> => {
  return await secureRequest(HttpMethod.Get, API_PATHS.PARTIES, {});
};

export const getContentTypes = async (): Promise<ContentType[]> => {
  return await secureRequest(HttpMethod.Get, API_PATHS.CONTENT_TYPES, {});
};

export const getPersonsForSearch = async (
  offset: number = 0,
  limit: number = 25,
  order: string = "id",
  filters: SearchFilters = {},
): Promise<PaginatedResponse<PersonOmniSearchResult>> => {
  const searchParams = new URLSearchParams();
  searchParams.set("order", order);
  searchParams.set("limit", String(limit));
  searchParams.set("offset", String(offset));
  const filterSearchString = getUrlFromFilters<SearchFilters>(filters);

  const url = `${API_PATHS.SEARCH_PERSONS}?${searchParams.toString()}${filterSearchString ? "&" + filterSearchString : ""}`;

  return await secureRequest(HttpMethod.Get, url, {});
};

export const getContentForPersonSearch = async (
  offset: number = 0,
  limit: number = 20,
  order: string = "id",
  person_id: string,
  filters: SearchFilters = {},
): Promise<PaginatedResponse<SearchContentType>> => {
  const searchParams = new URLSearchParams();
  searchParams.set("order", order);
  searchParams.set("limit", String(limit));
  searchParams.set("offset", String(offset));
  const filterSearchString = getUrlFromFilters<SearchFilters>(filters);

  const url = `${API_PATHS.SEARCH_PERSONS}${person_id}/content?${searchParams.toString()}${filterSearchString ? "&" + filterSearchString : ""}`;

  return await secureRequest(HttpMethod.Get, url, {});
};

export const getContentForSearch = async (
  offset: number = 0,
  limit: number = 20,
  order: string = "id",
  filters: SearchFilters = {},
): Promise<PaginatedResponse<SearchContentType>> => {
  const searchParams = new URLSearchParams();
  searchParams.set("order", order);
  searchParams.set("limit", String(limit));
  searchParams.set("offset", String(offset));
  const filterSearchString = getUrlFromFilters<SearchFilters>(filters);

  const url = `${API_PATHS.SEARCH_CONTENT}?${searchParams.toString()}${filterSearchString ? "&" + filterSearchString : ""}`;

  return await secureRequest(HttpMethod.Get, url, {});
};
