import Stack from "@mui/material/Stack";
import { Person } from "../../../types/Person";
import Typography from "@mui/material/Typography";
import { useMemo, useState } from "react";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  useMaterialReactTable,
} from "material-react-table";
import { usePersonEmails } from "../../../query/emails";
import { CrmSentEmail } from "../../../types/Email";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { PaginatedResponse } from "../../../types/utils";
import { PaginationState } from "@tanstack/react-table";
import SentEmailStatusWidget from "../../../components/SentEmailStatusWidget";
import SentEmailModal from "./SentEmailModal";

const SentEmails = ({ person }: { person: Person }) => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  });
  const [search, setSearch] = useState<string>("");
  const [selectedEmailId, setSelectedEmailId] = useState<string>("");
  const [searchFull, setSearchFull] = useState<string>("");
  const { data: emails, isLoading } = usePersonEmails(
    pagination.pageIndex,
    pagination.pageSize,
    person.id,
    searchFull,
  );

  const handleSubmit = () => {
    setSearchFull(search);
  };

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Typography variant="h2">Sent Emails</Typography>
        {emails ? (
          <EmailsTab
            emails={emails}
            isLoading={isLoading}
            pagination={pagination}
            setPagination={setPagination}
            search={search}
            personId={person.id}
            setSearch={setSearch}
            handleSubmit={handleSubmit}
            setSelectedEmailId={setSelectedEmailId}
          />
        ) : (
          <Box display="flex" justifyContent={"center"}>
            <Box position="absolute" sx={{ top: "50%" }}>
              <Stack direction={"column"} alignItems={"center"}>
                <CircularProgress />
              </Stack>
            </Box>
          </Box>
        )}
      </Stack>
      {selectedEmailId && (
        <SentEmailModal
          open={!!selectedEmailId}
          emailId={selectedEmailId}
          setOpen={setSelectedEmailId}
        />
      )}
    </>
  );
};

type EmailsTabProps = {
  emails: PaginatedResponse<CrmSentEmail>;
  isLoading: boolean;
  pagination: PaginationState;
  setPagination: any;
  search: string;
  personId: string;
  setSearch: (value: string) => void;
  handleSubmit: () => void;
  setSelectedEmailId: (value: string) => void;
};
const EmailsTab = (props: EmailsTabProps) => {
  const columns = useMemo<MRT_ColumnDef<CrmSentEmail>[]>(
    () => [
      {
        accessorKey: "subject",
        header: "Subject",
      },
      {
        accessorKey: "from_field",
        header: "Sent by",
      },
      {
        accessorFn: (row) => {
          const date = row.created_at;
          return date ? new Date(date).toLocaleDateString("en-GB") : "";
        },
        header: "Date created",
      },
      {
        header: "Status",
        enableColumnFilter: false,
        enableColumnFilterModes: false,
        showColumnFilters: false,
        Cell: ({ row }) => (
          <SentEmailStatusWidget
            personId={props.personId}
            emailId={row.original.email_id}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.isLoading],
  );
  return (
    <Box>
      <Box py={2}>
        <TextField
          value={props.search}
          onChange={(e) => props.setSearch(e.target.value)}
          size="small"
          id="outlined-basic"
          label="Search for content"
          name="search"
          variant="outlined"
          sx={{
            flex: 1,
            minWidth: "170px",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={props.handleSubmit}
          size="small"
          sx={{ marginX: 2 }}
        >
          Search
        </Button>
      </Box>
      <EmailsTable
        columns={columns}
        data={props.emails.results}
        totalCount={props.emails.total_count}
        setPagination={props.setPagination}
        pagination={props.pagination}
        isFetching={props.isLoading}
        setSelectedEmailId={props.setSelectedEmailId}
      />
    </Box>
  );
};

const EmailsTable = ({
  columns,
  data,
  totalCount,
  setPagination,
  pagination,
  isFetching,
  setSelectedEmailId,
}: {
  columns: MRT_ColumnDef<CrmSentEmail>[];
  data: CrmSentEmail[];
  totalCount: number;
  setPagination: React.Dispatch<React.SetStateAction<MRT_PaginationState>>;
  pagination: any;
  isFetching: boolean;
  setSelectedEmailId: (value: string) => void;
}) => {
  const table = useMaterialReactTable({
    columns,
    data,
    manualPagination: true,
    enableSorting: false,
    enableTopToolbar: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableRowSelection: false,
    enableExpandAll: false,
    rowCount: totalCount ?? 0,
    onPaginationChange: setPagination,
    muiTablePaperProps: {
      elevation: 0,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 25, 50],
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        event.preventDefault();
        setSelectedEmailId(row.original.id);
      },
      sx: {
        cursor: "pointer",
      },
    }),

    state: {
      pagination,
      isLoading: isFetching,
    },
  });

  return <MaterialReactTable table={table} />;
};

export default SentEmails;
